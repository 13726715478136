export default ({
  city, district, official, showOnlySpoe, showBgms, showVbgms, showOpvs,
}) => {
  const isDistrict = !district || district.id === official.district;
  const isCity = !city || city.id === official.city;
  const isfilterParty = showOnlySpoe ? official.party === 1 : true;
  const isBgm = official.type === 1;
  const isVbgm = official.type === 2;
  const isOpv = official.type === 8;
  return isDistrict && isCity && isfilterParty && ((isBgm && showBgms) || (isVbgm && showVbgms) || (isOpv && showOpvs));
};
