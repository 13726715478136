import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { SocketContext } from '../../../../AppContext';
import get from '../../../../util/get';
import Electiontypes from './electiontypes/Electiontypes';
import Parties from './parties/Parties';
import Presidents from './presidents/Presidents';

export default function Elections() {
  const socket = useContext(SocketContext);
  const [elections, setElections] = useState();
  const [electiontypes, setElectiontypes] = useState();
  const [parties, setParties] = useState();
  const [presidents, setPresidents] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'elections', elections, setElections);
    get(socket, onAlert, 'electiontypes', electiontypes, setElectiontypes);
    get(socket, onAlert, 'parties', parties, setParties);
    get(socket, onAlert, 'presidents', presidents, setPresidents);
  }, []);

  return (
    <Routes>
      <Route
        path="types/*"
        element={(
          <Electiontypes
            elections={elections}
            electiontypes={electiontypes}
            onSetElectiontypes={setElectiontypes}
          />
        )}
      />
      <Route
        path="parties/*"
        element={(
          <Parties
            elections={elections}
            parties={parties}
            onSetParties={setParties}
          />
        )}
      />
      <Route
        path="presidents/*"
        element={(
          <Presidents
            parties={parties}
            presidents={presidents}
            onSetPresidents={setPresidents}
          />
        )}
      />
      <Route path="*" element={<Navigate to="types" />} />
    </Routes>
  );
}
