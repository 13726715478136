export default ({ cities, districts, local }) => {
  if (!cities || !districts || !local) {
    return [];
  }
  return [
    {
      fieldName: 'type',
      title: 'Typ',
      instances: [
        { id: 'agreement_1', text: 'offenes Ziel', color: '#FFD700' },
        { id: 'agreement_2', text: 'Ziel in arbeit', color: '#FFA07A' },
        { id: 'agreement_3', text: 'abgeschlossenes Ziel', color: '#28A745' },
        { id: 'agreement_4', text: 'fehlgeschlagenes Ziel', color: '#000000' },
        { id: 'official_1', text: 'Geburtstag', color: '#6C757D' },
        { id: 'official_2', text: 'Geburtstag', color: '#6C757D' },
        { id: 'official_8', text: 'Geburtstag', color: '#6C757D' },
        { id: 'event', text: 'Event', color: '#CD0C1F' },
        { id: 'group', text: 'Mehr', color: '#B8860B' },
      ],
    }, {
      fieldName: 'district',
      title: 'Bezirk',
      instances: [
        ...districts.map(({ id, name }) => ({ id, text: name })),
        { id: 'state', text: local.state },
      ],
    }, {
      fieldName: 'city',
      title: 'Gemeinde',
      instances: [
        ...cities.map(({ id, name }) => ({ id, text: name })),
        { id: 'state', text: local.state },
        { id: 'district', text: local.district },
      ],
    },
  ];
};
