const filterElections = ({ elections, parties, city }) => elections
  .filter((item) => item.city === city.id)
  .map((item) => ({
    ...item,
    parties: item.parties.map((partyitem) => {
      const partyData = parties.find((party) => party.id === partyitem.party);
      return { ...partyitem, party: partyData.parent || partyData.id };
    }),
  }));

const calculateElectionResults = ({ swingConfig, cityElections, election }) => {
  const electionResults = cityElections.filter((item) => item.name === election.name);
  if (!electionResults || electionResults.length === 0) {
    return { elegible: 0, partyResults: [] };
  }
  if (electionResults.length > 1) {
    console.log(electionResults);
  }
  const electionResult = electionResults[0];
  const { eligible } = electionResult;
  const partyResults = [];
  swingConfig.parties.forEach((party) => {
    const partyResult = electionResult.parties.find((item) => item.party === party.id);
    const partyVotes = partyResult?.votes || 0;
    const partyVotesPercentage = parseFloat(((partyVotes / eligible) * 100).toFixed(2));
    partyResults.push({
      id: party.id,
      votes: partyVotes,
      votesPercentage: partyVotesPercentage,
    });
  });
  return { eligible, partyResults };
};

export default function SwingViewRowsMapper({
  swingConfig,
  elections,
  parties,
  cities,
}) {
  return cities.map((city) => {
    const row = {
      id: city.gkz,
      district: city.district,
      name: city.name,
      status: city.status,
    };
    const cityElections = filterElections({ elections, parties, city });
    const votesPercentageValues = {};
    Object.entries(swingConfig.elections).forEach(([electionId, election]) => {
      const { eligible, partyResults } = calculateElectionResults({ swingConfig, cityElections, election });
      row[`${electionId}-eligible`] = eligible;
      partyResults.forEach((party) => {
        row[`${party.id}-${electionId}-votes`] = party.votes;
        row[`${party.id}-${electionId}-votes-percentage`] = party.votesPercentage;
        if (!row[`${party.id}-max-votes`] || row[`${party.id}-max-votes`] < party.votes) {
          row[`${party.id}-max-votes`] = party.votes;
        }
        if (!row[`${party.id}-min-votes`] || row[`${party.id}-min-votes`] > party.votes) {
          row[`${party.id}-min-votes`] = party.votes;
        }
        if (!votesPercentageValues[party.id]) {
          votesPercentageValues[party.id] = [];
        }
        votesPercentageValues[party.id].push(party.votesPercentage);
      });
    });
    swingConfig.parties.forEach((party) => {
      const aveVotesPercField = `${party.id}-average-votes-percentage`;
      const averageSrohField = `${party.id}-average-sroh`;
      const maxVotesField = `${party.id}-max-votes`;
      const minVotesField = `${party.id}-min-votes`;
      const swingField = `${party.id}-swing`;

      const votesPercentageSum = votesPercentageValues[party.id].reduce((acc, num) => acc + num, 0);
      row[aveVotesPercField] = parseFloat((votesPercentageSum / votesPercentageValues[party.id].length).toFixed(2));

      const averageSrohValues = {};
      Object.entries(swingConfig.elections).forEach(([electionId]) => {
        const srohField = `${party.id}-${electionId}-sroh`;
        const votesPercentageField = `${party.id}-${electionId}-votes-percentage`;

        row[srohField] = parseFloat(Math.abs(row[votesPercentageField] - row[aveVotesPercField]).toFixed(2));
        if (!averageSrohValues[party.id]) {
          averageSrohValues[party.id] = [];
        }
        averageSrohValues[party.id].push(row[srohField]);
      });
      const averageSrohSum = averageSrohValues[party.id].reduce((acc, num) => acc + num, 0);
      row[averageSrohField] = parseFloat((averageSrohSum / averageSrohValues[party.id].length).toFixed(2));
      row[swingField] = parseFloat((((row[maxVotesField] - row[minVotesField]) / 100) * row[averageSrohField]).toFixed(2));
    });
    return row;
  });
}
