import React, { useContext, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  CurrentTimeIndicator,
  MonthView,
  WeekView,
  DayView,
  Toolbar,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  TodayButton,
  ViewSwitcher,
  AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';

import { Backdrop, CircularProgress } from '@mui/material';
import { SocketContext, LocalizationContext, UserContext } from '../../../../AppContext';
import hasWriteAccess from '../../../../util/hasWriteAccess';
import CalendarToolbar from './CalendarToolbar';
import CalendarAppointmentFormCommandLayout from './CalendarAppointmentFormCommandLayout';
import CalendarAppointmentFormDateEditor from './CalendarAppointmentFormDateEditor';
import CalendarAppointmentFormResourceEditor from './CalendarAppointmentFormResourceEditor';
import calendarEvents from './events/calendarEvents';
import calendarRessources from './ressources/calendarRessources';
import localization from './Calendar.local';
import CalendarContext from './CalendarContext';
import CalendarAppointmentContent from './CalendarAppointmentContent';
import CalendarTable from './CalendarTable';
import get from '../../../../util/get';
import GemoContext from '../GemoContext';

export default function Calendar() {
  const local = localization[useContext(LocalizationContext)];
  const user = useContext(UserContext);
  const socket = useContext(SocketContext);

  const {
    cities, city, districts, district,
  } = useContext(GemoContext);
  const [agreementcategories, setAgreementcategories] = useState();
  const [agreements, setAgreements] = useState();
  const [events, setEvents] = useState();
  const [officials, setOfficials] = useState();
  const [gridviews, setGridviews] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'agreementcategories', agreementcategories, setAgreementcategories);
    get(socket, onAlert, 'agreements', agreements, setAgreements);
    get(socket, onAlert, 'events', events, setEvents);
    get(socket, onAlert, 'officials', officials, setOfficials);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
  }, []);

  const [loading, setLoading] = React.useState(true);
  const [calEvents, setCalEvents] = React.useState([[], []]);
  const [mainResourceName, setMainRessourceName] = React.useState('type');
  const [resources, setResources] = React.useState([]);
  const [showAgreements, setShowAgreements] = React.useState(false);
  const [showBgms, setShowBgms] = React.useState(false);
  const [showVbgms, setShowVbgms] = React.useState(false);
  const [showOpvs, setShowOpvs] = React.useState(false);
  const [showOnlySpoe, setShowOnlySpoe] = React.useState(true);
  const [editingAppointment, setEditingAppointment] = React.useState();
  const [addedAppointment, setAddedAppointment] = React.useState();
  const [appointmentChanges, setAppointmentChanges] = React.useState();
  const [appointmentTooltipVisibile, setAppointmentTooltipVisibile] = React.useState(false);
  const [appointmentTooltipMeta, setAppointmentTooltipMeta] = React.useState();
  const [appointmentFormVisible, setAppointmentFormVisible] = React.useState(false);
  const [tableMode, setTableMode] = React.useState(false);
  const [archive, setArchive] = React.useState(false);

  const onClickMoreEvent = (event) => {
    setEditingAppointment(event);
    setAppointmentFormVisible(true);
  };

  const writeAccess = React.useMemo(() => hasWriteAccess(user, district?.id, city?.id), [user, district, city]);
  const context = React.useMemo(() => ({ districts, cities, onClickMoreEvent }), [districts, cities]);

  const isAppointmentFormReadOnly = React.useMemo(() => {
    if (editingAppointment) {
      return editingAppointment.type !== 'event' && editingAppointment.type !== undefined;
    }
    return false;
  }, [editingAppointment]);

  const CalendarToolbarMemoized = React.useMemo(() => (
    <CalendarToolbar
      local={local}
      events={calEvents[0]}
      mainResourceName={mainResourceName}
      resources={resources}
      showAgreements={showAgreements}
      showBgms={showBgms}
      showVbgms={showVbgms}
      showOpvs={showOpvs}
      showOnlySpoe={showOnlySpoe}
      onMainRessourceNameChange={setMainRessourceName}
      onShowAgreementsChange={setShowAgreements}
      onShowBgmsChange={setShowBgms}
      onShowVbgmsChange={setShowVbgms}
      onShowOpvsChange={setShowOpvs}
      onShowOnlySpoeChange={setShowOnlySpoe}
      onModeChange={() => setTableMode(true)}
      archive={archive}
      setArchive={setArchive}
    />
  ), [local, calEvents, mainResourceName, resources, showAgreements, showBgms, showVbgms, showOpvs, showOnlySpoe, archive]);

  React.useEffect(() => setResources(calendarRessources({ cities, districts, local })), [cities, districts, local]);

  React.useEffect(() => {
    if (agreements && agreementcategories && events && officials) {
      setCalEvents(calendarEvents({
        district,
        city,
        agreements: agreements || [],
        agreementcategories: agreementcategories || [],
        events: events || [],
        officials: officials || [],
        showAgreements,
        showBgms,
        showOnlySpoe,
        showVbgms,
        showOpvs,
        archive,
      }));
      setLoading(false);
    }
  }, [agreements, agreementcategories, city, district, events, officials, showAgreements, showBgms, showOnlySpoe, showVbgms, showOpvs, archive]);

  const acknowledgePost = ({ error, payload }) => {
    if (error) {
      console.log(error);
    } else {
      setEvents(events ? [...events, payload] : [payload]);
    }
  };

  const acknowledgePatch = ({ error, payload }) => {
    if (error) {
      console.log(error);
    } else {
      setEvents(events.map((event) => (event.id === payload.id ? payload : event)));
    }
  };

  const acknowledgeDestroy = ({ error, payload }) => {
    if (error) {
      console.log(error);
    } else {
      setEvents(events.filter((event) => event.id !== payload.id));
    }
  };

  const onCommitChanges = (changes) => {
    if (changes.added) {
      socket.emit('events.post', changes.added, acknowledgePost);
    }
    if (changes.changed) {
      Object.keys(changes.changed).forEach((key) => {
        Object.keys(changes.changed[key]).forEach((field) => {
          const patch = {
            id: key,
            field,
            value: changes.changed[key][field],
          };
          socket.emit('events.patch', patch, acknowledgePatch);
        });
      });
    }
    if (changes.deleted) {
      socket.emit('events.destroy', { id: changes.deleted }, acknowledgeDestroy);
    }
  };

  if (loading) {
    return (
      <Backdrop open sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (tableMode) {
    return (
      <CalendarTable
        districts={districts}
        cities={cities}
        events={calEvents[0]}
        onChangeMode={() => setTableMode(false)}
        gridviews={gridviews}
        onSetGridviews={setGridviews}
      />
    );
  }

  return (
    <CalendarContext.Provider value={context}>
      <Paper sx={{ height: '100%' }}>
        {writeAccess ? (
          <Scheduler key="1" data={calEvents[1]} locale="de-AT" firstDayOfWeek={1}>
            <ViewState />
            <MonthView name={local.month} />
            <WeekView name={local.week} />
            <DayView name={local.day} />
            <EditingState
              addedAppointment={addedAppointment}
              onAddedAppointmentChange={(addedAppointmentChange) => setAddedAppointment({
                ...addedAppointmentChange, allDay: false, type: 'event', city: city?.id, district: district?.id,
              })}
              editingAppointment={editingAppointment}
              onEditingAppointmentChange={(editingAppointmentChange) => setEditingAppointment({ ...editingAppointmentChange })}
              appointmentChanges={appointmentChanges}
              onAppointmentChangesChange={((changes) => setAppointmentChanges(changes))}
              onCommitChanges={onCommitChanges}
            />
            <IntegratedEditing />
            <Appointments appointmentContentComponent={CalendarAppointmentContent} />
            <AppointmentTooltip
              showCloseButton
              showOpenButton={writeAccess}
              visible={(!appointmentTooltipMeta || appointmentTooltipMeta?.data?.type !== 'group') && appointmentTooltipVisibile}
              onVisibilityChange={(visible) => setAppointmentTooltipVisibile(visible)}
              onAppointmentMetaChange={(meta) => setAppointmentTooltipMeta(meta)}
            />
            <AppointmentForm
              messages={local}
              readOnly={isAppointmentFormReadOnly}
              commandLayoutComponent={CalendarAppointmentFormCommandLayout}
              dateEditorComponent={CalendarAppointmentFormDateEditor}
              resourceEditorComponent={CalendarAppointmentFormResourceEditor}
              appointmentData={editingAppointment || undefined}
              visible={appointmentFormVisible}
              onVisibilityChange={(visible) => setAppointmentFormVisible(visible)}
            />
            <Toolbar flexibleSpaceComponent={() => CalendarToolbarMemoized} />
            <TodayButton messages={local} />
            <DateNavigator />
            <ViewSwitcher />
            <AllDayPanel messages={local} />
            <CurrentTimeIndicator shadePreviousCells shadePreviousAppointments />
            <Resources
              data={resources}
              mainResourceName={mainResourceName}
            />
          </Scheduler>
        ) : (
          <Scheduler key="2" data={calEvents[1]} locale="de-AT" firstDayOfWeek={1}>
            <ViewState />
            <MonthView name={local.month} />
            <WeekView name={local.week} />
            <DayView name={local.day} />
            <Appointments appointmentContentComponent={CalendarAppointmentContent} />
            <AppointmentTooltip
              showCloseButton
              visible={(!appointmentTooltipMeta || appointmentTooltipMeta?.data?.type !== 'group') && appointmentTooltipVisibile}
              onVisibilityChange={(visible) => setAppointmentTooltipVisibile(visible)}
              onAppointmentMetaChange={(meta) => setAppointmentTooltipMeta(meta)}
            />
            <Toolbar flexibleSpaceComponent={() => CalendarToolbarMemoized} />
            <TodayButton messages={local} />
            <DateNavigator />
            <ViewSwitcher />
            <AllDayPanel messages={local} />
            <CurrentTimeIndicator shadePreviousCells shadePreviousAppointments />
            <Resources
              data={resources}
              mainResourceName={mainResourceName}
            />
          </Scheduler>
        )}
      </Paper>
    </CalendarContext.Provider>
  );
}
