import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Label from '../../../../components/Label';

export default function InfoCityReports({ infoReports }) {
  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Grid container spacing={2}>
        { infoReports.map((report) => (
          <Grid key={report.name} container item xs={12}>
            <Typography variant="h6">Berichte</Typography>
            <Grid item xs={12}>
              <Typography variant="body2">{report.name}</Typography>
            </Grid>
            { report.status.map((item) => (
              <Grid key={item.name} item xs={3}>
                <Label label={item.name} value={item.length} />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

InfoCityReports.propTypes = {
  infoReports: PropTypes.arrayOf(PropTypes.shape({})),
};

InfoCityReports.defaultProps = {
  infoReports: undefined,
};
