import React from 'react';
import {
  Box, Autocomplete, TextField, Button, Modal, Checkbox, FormControlLabel, Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';

const GRID = 'districts';

export default function DistrictsToolbar({
  gridview, gridviews, onChangeGridview, onPostGridview, onPatchGridview, onDeleteGridview,
}) {
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState(gridview?.view || '');
  const [global, setGlobal] = React.useState(gridview?.global || false);
  const [noName, setNoName] = React.useState(!gridview?.view);

  React.useEffect(() => {
    setView(gridview?.view || '');
    setGlobal(gridview?.global || false);
    setNoName(!gridview?.view);
  }, [gridview]);

  const onSaveClick = () => {
    if (!noName) {
      if (gridview?.grid) {
        onPatchGridview(view, global);
      } else {
        onPostGridview(GRID, view, global);
      }
      setOpen(false);
    } else {
      setNoName(true);
    }
  };

  const onChangeView = (e) => {
    setView(e.target.value);
    if (noName) {
      setNoName(false);
    }
    if (e.target.value === '') {
      setNoName(true);
    }
  };

  return (
    <GridToolbarContainer>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          p: 4,
        }}
        >
          <Grid container spacing="2">
            <Grid item xs={12}>
              <TextField
                label="Ansicht"
                value={view}
                onChange={onChangeView}
                error={noName}
                helperText={noName ? 'Kein Name!' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                sx={{ p: 1, pt: 2 }}
                label="Globale Ansicht?"
                control={(
                  <Checkbox
                    checked={global}
                    onChange={(e) => setGlobal(e.target.checked)}
                  />
              )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button fullWidth variant="contained" color="secondary" size="small" onClick={() => setOpen(false)}>Abbrechen</Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button fullWidth variant="contained" size="small" onClick={onSaveClick}>Speichern</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      { gridviews?.filter((item) => item.grid === GRID).length > 0 && (
      <Autocomplete
        size="small"
        sx={{ width: '250px' }}
        value={gridview?.view ? gridview : null}
        options={gridviews.filter((item) => item.grid === GRID) || null}
        onChange={(e, selected) => onChangeGridview(selected)}
        getOptionLabel={((option) => option.view)}
        renderInput={(params) => (
          <TextField
            fullWidth
                  // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            color="secondary"
            margin="dense"
            label="Ansicht"
          />
        )}
      />
      )}
      { !gridview?.view && (<Button variant="text" size="small" onClick={() => setOpen(true)}>Ansicht erstellen</Button>)}
      { gridview?.view && (<Button variant="text" size="small" onClick={() => setOpen(true)}>Ansicht speichern</Button>)}
      { gridview?.view && (<Button variant="text" size="small" onClick={onDeleteGridview}>Ansicht löschen</Button>)}
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

DistrictsToolbar.propTypes = {
  gridview: PropTypes.shape({
    grid: PropTypes.string,
    view: PropTypes.string,
    global: PropTypes.bool,
  }),
  gridviews: PropTypes.arrayOf(PropTypes.shape({})),
  onChangeGridview: PropTypes.func.isRequired,
  onPostGridview: PropTypes.func.isRequired,
  onPatchGridview: PropTypes.func.isRequired,
  onDeleteGridview: PropTypes.func.isRequired,
};

DistrictsToolbar.defaultProps = {
  gridview: undefined,
  gridviews: undefined,
};
