import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { Grid } from '@mui/material';
import { LocalizationContext } from '../../../../AppContext';

import InfoCityAgreements from './InfoCityAgreements';
import InfoCityReports from './InfoCityReports';
import InfoStatisticColumn from './InfoStatisticColumn';
import InfoSummaryDetails from './InfoSummaryDetails';
import InfoSummarySettings from './InfoSummarySettings';
import localization from './InfoSummary.local';

export default function InfoCity({
  cities,
  citiesInfo,
  // citysettingdefinitions,
  // citysettings,
  district,
  group,
  // onSetCitysettings,
  onSetGroup,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Button size="small" variant="text" color={group ? 'primary' : 'secondary'} onClick={() => onSetGroup(!group)}>
        {local.group}
      </Button>
      { citiesInfo && (
      <Grid container spacing={1}>
        <Grid container item xs={12} sm={6}>
          <Box width="100%" display="flex" flexDirection="column" gap={1}>
            { citiesInfo && <InfoSummaryDetails citiesInfo={citiesInfo} cities={cities} district={district} /> }
            { citiesInfo.agreements && <InfoCityAgreements infoAgreements={citiesInfo.agreements} /> }
            { citiesInfo.citysettingdefinitions && <InfoSummarySettings citysettingdefinitions={citiesInfo.citysettingdefinitions} /> }
            { citiesInfo.reports && <InfoCityReports infoReports={citiesInfo.reports} /> }
          </Box>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Box width="100%" display="flex" flexDirection="column" gap={1}>
            <InfoStatisticColumn title={local.absolutes} data={citiesInfo.absolutes} labels={[local.party, local.absolutes]} />
            <InfoStatisticColumn title={local.mayors} data={citiesInfo.mayors} labels={[local.party, local.mayors]} />
            <InfoStatisticColumn title={local.mandates} data={citiesInfo.mandates} labels={[local.party, local.mandates]} />
            <InfoStatisticColumn title={local.vicemayors} data={citiesInfo.vicemayors} labels={[local.party, local.vicemayors]} />
          </Box>
        </Grid>
      </Grid>
      )}
    </Box>
  );
}

InfoCity.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  citiesInfo: PropTypes.arrayOf(PropTypes.shape({})),
  // citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  // citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  group: PropTypes.bool.isRequired,
  onSetGroup: PropTypes.func.isRequired,
};

InfoCity.defaultProps = {
  cities: undefined,
  citiesInfo: undefined,
  // citysettingdefinitions: undefined,
  // citysettings: undefined,
  district: undefined,
};
