import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import CitysettingdefinitionsPatch from './CitysettingdefinitionsPatch';
import CitysettingdefinitionsTable from './CitysettingdefinitionsTable';
import { SocketContext } from '../../../../AppContext';
import get from '../../../../util/get';

export default function Citysettingdefinitions() {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const [citysettingdefinitions, setCitysettingdefinitions] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions);
  }, []);

  return (
    <Routes>
      <Route
        path=":citysettingdefinitionid"
        element={(
          <CitysettingdefinitionsPatch
            citysettingdefinitions={citysettingdefinitions}
            onBack={() => navigate('/settings/citysettingdefinitions')}
            onSetCitysettingdefinitions={setCitysettingdefinitions}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <CitysettingdefinitionsTable
            citysettingdefinitions={citysettingdefinitions}
            onRowClick={(id) => navigate(`/settings/citysettingdefinitions/${id}`)}
            onSetCitysettingdefinitions={setCitysettingdefinitions}
          />
        )}
      />
    </Routes>
  );
}
