import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Reportdefinitions from './reportdefinitions/Reportdefinitions';
import Reportstatuses from './reportstatuses/Reportstatuses';
import { SocketContext } from '../../../../AppContext';
import get from '../../../../util/get';

export default function Reports() {
  const socket = useContext(SocketContext);
  const [citysettingdefinitions, setCitysettingdefinitions] = useState();
  const [reportdefinitions, setReportdefinitions] = useState();
  const [reports, setReports] = useState();
  const [reportstatuses, setReportstatuses] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions);
    get(socket, onAlert, 'reportdefinitions', reportdefinitions, setReportdefinitions);
    get(socket, onAlert, 'reports', reports, setReports);
    get(socket, onAlert, 'reportstatuses', reportstatuses, setReportstatuses);
  }, []);

  return (
    <Routes>
      <Route
        path="definitions/*"
        element={(
          <Reportdefinitions
            citysettingdefinitions={citysettingdefinitions}
            reportdefinitions={reportdefinitions}
            reports={reports}
            onSetReportdefinitions={setReportdefinitions}
          />
        )}
      />
      <Route
        path="statuses/*"
        element={(
          <Reportstatuses
            reports={reports}
            reportstatuses={reportstatuses}
            onSetReportstatuses={setReportstatuses}
          />
        )}
      />
      <Route path="*" element={<Navigate to="definitions" />} />
    </Routes>
  );
}
