import moment from 'moment';

const type = {
  1: 'Bgm.',
  2: 'Vzbgm.',
  8: 'OPV',
};

export default ({ official }) => ({
  title: `${type[official.type]} ${official.firstname} ${official.lastname} (${moment(official.birthdate, 'DD.MM.YYYY').format('yyyy')}) ${official.phone ? official.phone : ''}`,
  startDate: `${moment(official.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD')}T00:00`,
  endDate: `${moment(official.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD')}T23:59`,
  allDay: true,
  rRule: 'RRULE:FREQ=YEARLY',
  type: `official_${official.type}`,
  district: official.district,
  city: official.city,
  object: official,
  notes: `${moment(official.birthdate, 'DD.MM.YYYY').format('yyyy')}${official.phone ? ` - ${official.phone}` : ''}`,
});
