import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

export default function Loading({ sx }) {
  return (
    <Box display="flex" justifyContent="center" sx={sx}>
      <CircularProgress color="primary" />
    </Box>
  );
}

Loading.propTypes = {
  sx: PropTypes.shape({}),
};

Loading.defaultProps = {
  sx: undefined,
};
