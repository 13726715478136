import React, { useContext, useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Toolbar from './LogsToolbar';
import ColumnDefinition from './LogsColumnDefinition';
import RowsMapper from './LogsRowsMapper';
import localization from './Logs.local';
import get from '../../../../util/get';

export default function Logs() {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [cities, setCities] = useState();
  const [districts, setDistricts] = useState();
  const [logs, setLogs] = useState();
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'cities', cities, setCities);
    get(socket, onAlert, 'districts', districts, setDistricts);
    get(socket, onAlert, 'logs', logs, setLogs);
  }, []);
  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper(local, logs, cities, districts)), [logs, cities, districts]);

  return (
    <Paper sx={{ height: '100%' }}>
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          components={{ Toolbar }}
        />
      )}
    </Paper>
  );
}
