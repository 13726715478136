import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, Grid,
  // FormControl, FormControlLabel, FormLabel, RadioGroup, Radio,
  Autocomplete, TextField, Button, IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { LocalizationContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import localization from './SwingConfig.local';

const MODE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

const SCALE = {
  STATE: 'STATE',
  DISTRICT: 'DISTRICT',
  CITY: 'CITY',
};

export default function SwingConfig({
  swingConfig,
  // cities,
  // districts,
  elections,
  parties,
  onChange,
  onElectionAdd,
  onElectionChange,
  onElectionDelete,
}) {
  const local = localization[React.useContext(LocalizationContext)];
  const [alert, setAlert] = useState();

  const electionOptions = React.useMemo(() => {
    if (elections) {
      return Array.from(
        new Set(elections.map((item) => item.name || '').sort((a, b) => parseInt(b.slice(-4), 10) - parseInt(a.slice(-4), 10))),
      );
    }
    return [];
  }, [elections]);

  const checkConfig = () => {
    if (!swingConfig.parties || swingConfig.parties.length === 0) {
      setAlert(501);
    } else if (!swingConfig.elections || Object.keys(swingConfig.elections).length === 0) {
      setAlert(502);
    } else {
      let validElections = true;
      Object.values(swingConfig.elections).forEach((election) => {
        validElections = (validElections && !!election.name);
      });
      if (!validElections) {
        setAlert(503);
      } else {
        onChange('mode', MODE.VIEW);
      }
    }
  };

  return (
    <Paper sx={{
      p: 2, pt: 5, position: 'relative', mb: 2,
    }}
    >
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            disableClearable
            multiple
            value={swingConfig.parties}
            options={parties ? parties.filter((item) => item.parent === null) : []}
            onChange={(e, selected) => onChange('parties', selected)}
            getOptionLabel={((option) => option.name)}
            renderInput={(params) => (
              <TextField
                fullWidth
                    // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                color="secondary"
                margin="dense"
                label={local.parties}
              />
            )}
          />
        </Grid>
        { Object.entries(swingConfig.elections).map(([electionId, election]) => (
          <Grid key={electionId} item xs={12} sm={5} lg={4} sx={{ display: 'flex' }}>
            <IconButton sx={{ mt: 2, mb: 2, mr: 1 }} onClick={() => onElectionDelete(electionId)}><DeleteIcon /></IconButton>
            <Autocomplete
              disableClearable
              fullWidth
              value={election.name}
              options={electionOptions}
              onChange={(e, selected) => onElectionChange(electionId, 'name', selected)}
              getOptionLabel={((option) => option)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                    // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  color="secondary"
                  margin="dense"
                  label={local.electiontype}
                />
              )}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          <Button onClick={() => onElectionAdd()}>
            <AddIcon sx={{ mr: 1 }} />
            {local.addElection}
          </Button>
        </Grid>
      </Grid>
      <Button fullWidth variant="contained" sx={{ mt: 1 }} onClick={checkConfig}>{local.view}</Button>
    </Paper>
  );
}

SwingConfig.propTypes = {
  swingConfig: PropTypes.shape({
    scale: PropTypes.oneOf([SCALE.STATE, SCALE.DISTRICT, SCALE.CITY]),
    district: PropTypes.shape({
      id: PropTypes.number,
    }),
    city: PropTypes.shape({
      id: PropTypes.number,
    }),
    elections: PropTypes.shape({}),
    parties: PropTypes.arrayOf(PropTypes.shape({
    })),
  }).isRequired,
  // cities: PropTypes.arrayOf(PropTypes.shape({})),
  // districts: PropTypes.arrayOf(PropTypes.shape({})),
  elections: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string,
  })),
  parties: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  onElectionAdd: PropTypes.func.isRequired,
  onElectionChange: PropTypes.func.isRequired,
  onElectionDelete: PropTypes.func.isRequired,

};

SwingConfig.defaultProps = {
  // cities: undefined,
  // districts: undefined,
  parties: undefined,
  elections: undefined,
  electiontypes: undefined,
};
