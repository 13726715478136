export default function SwingViewInitialState(swingConfig) {
  const state = {
    columns: {
      columnVisibilityModel: {},
    },
  };

  Object.entries(swingConfig.elections).forEach(([electionId]) => {
    state.columns.columnVisibilityModel[`${electionId}-eligible`] = false;
    swingConfig.parties.forEach((party) => {
      state.columns.columnVisibilityModel[`${party.id}-${electionId}-votes`] = false;
      state.columns.columnVisibilityModel[`${party.id}-${electionId}-votes-percentage`] = false;
      state.columns.columnVisibilityModel[`${party.id}-${electionId}-sroh`] = false;
    });
  });
  swingConfig.parties.forEach((party) => {
    state.columns.columnVisibilityModel[`${party.id}-average-votes-percentage`] = false;
    state.columns.columnVisibilityModel[`${party.id}-average-sroh`] = false;
    state.columns.columnVisibilityModel[`${party.id}-max-votes`] = false;
    state.columns.columnVisibilityModel[`${party.id}-min-votes`] = false;
  });
  return state;
}
