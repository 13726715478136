import { sortByDate } from '../../../../util/sort';
import { filterByDistrict, filterByDistrictAndElectiontypeCouncil } from '../../../../util/filter';

function mapAgreementsToStatus(district, agreements, agreementstatuses) {
  const filteredList = agreements.filter((item) => filterByDistrict(item, district) && item.active);
  const result = agreementstatuses
    .map((status) => ({ name: status.name, length: filteredList.filter((item) => item.status === status.id).length }))
    .filter((item) => item.length > 0);
  return result.length > 0 ? result : undefined;
}

function mapCitysettingsdefinitions(cities, district, citysettingdefinitions, citysettings) {
  const filteredList = citysettings.filter((item) => filterByDistrict(item, district));
  const currentCities = district ? cities.filter((item) => item.district === district.id) : cities;
  const result = citysettingdefinitions
    .filter((item) => item.active && item.display && !['DATE', 'TEXT'].includes(item.type))
    .map((definition) => {
      const settings = filteredList.filter((item) => item.definition === definition.id);
      let value;
      if (definition.type === 'BOOLEAN') {
        value = {
          true: settings.filter((item) => item.value === '1').length,
          false: settings.filter((item) => item.value === '0').length,
        };
        value.unset = currentCities.length - value.true - value.false;
      }
      if (definition.type === 'NUMBER') {
        value = 0;
        settings.forEach((item) => {
          value += parseInt(item.value, 10);
        });
      }
      if (definition.type === 'DROPDOWN') {
        value = {};
        definition.options.forEach((option) => {
          value[option.name] = settings.filter((item) => item.value === option.id.toString()).length;
        });
      }
      return { ...definition, value };
    });
  return result.length > 0 ? result : undefined;
}

const OFFICIALTYPES = {
  MAYOR: 1,
  VICEMAYOR: 2,
  EXECUTIVE_COUNCIL: 5,
  COUNCIL: 6,
};

function mapParty(item, parties, group) {
  const itemParty = parties.find(({ id }) => id === item.party);
  return group && itemParty.parent ? parties.find(({ id }) => id === itemParty.parent) : itemParty;
}

function mapOfficials(district, officials, group, parties) {
  const filteredOfficials = officials.filter((official) => filterByDistrict(official, district));
  const foundMayors = filteredOfficials.filter((official) => official.type === OFFICIALTYPES.MAYOR);
  const foundVicemayors = filteredOfficials.filter((official) => official.type === OFFICIALTYPES.VICEMAYOR);
  const foundExecutiveCouncils = filteredOfficials.filter((official) => official.type === OFFICIALTYPES.EXECUTIVE_COUNCIL);
  const foundCouncils = filteredOfficials.filter((official) => official.type === OFFICIALTYPES.COUNCIL);
  const mayors = {
    [parties[0].id]: { ...parties[0], value: 0 },
    [parties[1].id]: { ...parties[1], value: 0 },
    [parties[2].id]: { ...parties[2], value: 0 },
    [parties[3].id]: { ...parties[3], value: 0 },
  };
  foundMayors.forEach((item) => {
    const party = mapParty(item, parties, group);
    if (mayors[party.id]) {
      mayors[party.id].value += 1;
    } else {
      mayors[party.id] = { ...party, value: 1 };
    }
  });
  const vicemayors = {
    [parties[0].id]: { ...parties[0], value: 0 },
    [parties[1].id]: { ...parties[1], value: 0 },
    [parties[2].id]: { ...parties[2], value: 0 },
    [parties[3].id]: { ...parties[3], value: 0 },
  };
  foundVicemayors.forEach((item) => {
    const party = mapParty(item, parties, group);
    if (vicemayors[party.id]) {
      vicemayors[party.id].value += 1;
    } else {
      vicemayors[party.id] = { ...party, value: 1 };
    }
  });
  const executivecouncils = {
    [parties[0].id]: { ...parties[0], value: 0 },
    [parties[1].id]: { ...parties[1], value: 0 },
    [parties[2].id]: { ...parties[2], value: 0 },
    [parties[3].id]: { ...parties[3], value: 0 },
  };
  foundExecutiveCouncils.forEach((item) => {
    const party = mapParty(item, parties, group);
    if (executivecouncils[party.id]) {
      executivecouncils[party.id].value += 1;
    } else {
      executivecouncils[party.id] = { ...party, value: 1 };
    }
  });
  const councils = {
    [parties[0].id]: { ...parties[0], value: 0 },
    [parties[1].id]: { ...parties[1], value: 0 },
    [parties[2].id]: { ...parties[2], value: 0 },
    [parties[3].id]: { ...parties[3], value: 0 },
  };
  foundCouncils.forEach((item) => {
    const party = mapParty(item, parties, group);
    if (councils[party.id]) {
      councils[party.id].value += 1;
    } else {
      councils[party.id] = { ...party, value: 1 };
    }
  });
  return [mayors, vicemayors, executivecouncils, councils];
}

function mapElectionresult(cities, district, electionresults, group, parties) {
  const absolutes = {
    0: {
      name: process.env.REACT_APP_FLAG_COALITION === '1' ? 'KOALITIONEN' : 'KEINE ABSOLUTE',
      color: 'grey',
      value: district ? cities.filter((item) => item.district === district.id).length : cities.length,
    },
    [parties[0].id]: { ...parties[0], value: 0 },
    [parties[1].id]: { ...parties[1], value: 0 },
    [parties[2].id]: { ...parties[2], value: 0 },
    [parties[3].id]: { ...parties[3], value: 0 },
  };
  const mandates = {
    [parties[0].id]: { ...parties[0], value: 0 },
    [parties[1].id]: { ...parties[1], value: 0 },
    [parties[2].id]: { ...parties[2], value: 0 },
    [parties[3].id]: { ...parties[3], value: 0 },
  };
  const councilParties = {};
  const mandatesByCity = { total: 0 };

  const filteredCouncilElectionresults = electionresults.filter((item) => filterByDistrictAndElectiontypeCouncil(item, district));
  cities.filter((item) => filterByDistrict(item, district)).forEach((city) => {
    const cityCouncilElectionresults = filteredCouncilElectionresults.filter((item) => item.city === city.id).sort(sortByDate);
    if (cityCouncilElectionresults[0]) {
      cityCouncilElectionresults[0].parties.filter((item) => item.mandates).forEach((item) => {
        const party = mapParty(item, parties, group);
        mandatesByCity.total += item.mandates;
        if (mandatesByCity[city.id]) {
          mandatesByCity[city.id] += item.mandates;
        } else {
          mandatesByCity[city.id] = item.mandates;
        }
        if (mandates[party.id]) {
          mandates[party.id].value += item.mandates;
        } else {
          mandates[party.id] = { ...party, value: item.mandates };
        }
        if (councilParties[city.id] && party) {
          councilParties[city.id].push({ ...party, mandates: item.mandates });
        } else if (party) {
          councilParties[city.id] = [{ ...party, mandates: item.mandates }];
        }
      });
    }
  });
  Object.keys(councilParties).forEach((key) => {
    councilParties[key].forEach((item) => {
      if (item.mandates / mandatesByCity[key] >= 0.5) {
        if (absolutes[0]) {
          absolutes[0].value -= 1;
          if (absolutes[0].value <= 0) {
            delete absolutes[0];
          }
        }
        if (absolutes[item.id]) {
          absolutes[item.id].value += 1;
        } else {
          absolutes[item.id] = { ...item, value: 1 };
        }
      }
    });
  });
  return [absolutes, councilParties, mandates];
}

function mapReportsToDefinitionAndStatus(district, reportdefinitions, reports, reportstatuses) {
  const filteredList = reports.filter((item) => filterByDistrict(item, district));
  const definitions = reportdefinitions
    .filter((item) => item.active).map((definition) => {
      const result = { name: definition.name, total: 0, status: [] };
      reportstatuses.forEach((status) => {
        const { length } = filteredList.filter((item) => item.active && item.definition === definition.id && item.status === status.id);
        if (length > 0) {
          result.total += length;
          result.status.push({ name: status.name, length });
        }
      });
      return result;
    }).filter((item) => item.total > 0);
  return definitions.length > 0 ? definitions : undefined;
}

export default function infoSummaryMapping(
  agreements,
  agreementstatuses,
  cities,
  citysettingdefinitions,
  citysettings,
  district,
  electionresults,
  group,
  officials,
  parties,
  reportdefinitions,
  reports,
  reportstatuses,
) {
  const summaryAgreements = mapAgreementsToStatus(district, agreements, agreementstatuses);
  const [mayors, vicemayors, executivecouncils, councils] = mapOfficials(district, officials, group, parties);
  const [absolutes, councilParties, mandates] = mapElectionresult(cities, district, electionresults, group, parties);
  const cityReports = mapReportsToDefinitionAndStatus(district, reportdefinitions, reports, reportstatuses);
  const summaryCitysettingdefinitions = mapCitysettingsdefinitions(cities, district, citysettingdefinitions, citysettings);
  return {
    agreements: summaryAgreements,
    absolutes,
    citysettingdefinitions: summaryCitysettingdefinitions,
    councils,
    councilParties,
    executivecouncils,
    mandates,
    mayors,
    reports: cityReports,
    vicemayors,
  };
}
