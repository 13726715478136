export default function SwingViewRowsMapper({
  swingConfig,
  districts,
  cityRows,
}) {
  return districts.map((district) => {
    const row = {
      id: district.gkz,
      district: district.id,
      name: district.name,
      status: 'Bezirk',
    };
    const votesPercentageValues = {};
    Object.entries(swingConfig.elections).forEach(([electionId]) => {
      const votesField = `${electionId}-eligible`;
      cityRows.filter((item) => item.district === district.id).forEach((cityRow) => {
        row[votesField] = row[votesField] ? row[votesField] + cityRow[votesField] : cityRow[votesField];
        swingConfig.parties.forEach((party) => {
          const partyVotesField = `${party.id}-${electionId}-votes`;
          row[partyVotesField] = row[partyVotesField] ? row[partyVotesField] + cityRow[partyVotesField] : cityRow[partyVotesField];
        });
      });
      swingConfig.parties.forEach((party) => {
        const partyVotesField = `${party.id}-${electionId}-votes`;
        const maxVotesField = `${party.id}-max-votes`;
        const minVotesField = `${party.id}-min-votes`;
        const votesPercentageField = `${party.id}-${electionId}-votes-percentage`;

        row[maxVotesField] = row[maxVotesField] && row[maxVotesField] > row[partyVotesField] ? row[maxVotesField] : row[partyVotesField];
        row[minVotesField] = row[minVotesField] && row[minVotesField] < row[partyVotesField] ? row[minVotesField] : row[partyVotesField];
        row[votesPercentageField] = parseFloat(((row[partyVotesField] / row[votesField]) * 100).toFixed(2));
        if (!votesPercentageValues[party.id]) {
          votesPercentageValues[party.id] = [];
        }
        votesPercentageValues[party.id].push(row[votesPercentageField]);
      });
    });
    swingConfig.parties.forEach((party) => {
      const aveVotesPercField = `${party.id}-average-votes-percentage`;
      const averageSrohField = `${party.id}-average-sroh`;
      const maxVotesField = `${party.id}-max-votes`;
      const minVotesField = `${party.id}-min-votes`;
      const swingField = `${party.id}-swing`;

      const votesPercentageSum = votesPercentageValues[party.id].reduce((acc, num) => acc + num, 0);
      row[aveVotesPercField] = parseFloat((votesPercentageSum / votesPercentageValues[party.id].length).toFixed(2));

      const averageSrohValues = {};
      Object.entries(swingConfig.elections).forEach(([electionId]) => {
        const srohField = `${party.id}-${electionId}-sroh`;
        const votesPercentageField = `${party.id}-${electionId}-votes-percentage`;

        row[srohField] = parseFloat(Math.abs(row[votesPercentageField] - row[aveVotesPercField]).toFixed(2));
        if (!averageSrohValues[party.id]) {
          averageSrohValues[party.id] = [];
        }
        averageSrohValues[party.id].push(row[srohField]);
      });
      const averageSrohSum = averageSrohValues[party.id].reduce((acc, num) => acc + num, 0);
      row[averageSrohField] = parseFloat((averageSrohSum / averageSrohValues[party.id].length).toFixed(2));
      row[swingField] = parseFloat((((row[maxVotesField] - row[minVotesField]) / 100) * row[averageSrohField]).toFixed(2));
    });
    return row;
  });
}
