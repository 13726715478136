import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { LocalizationContext } from '../../../../AppContext';
import Label from '../../../../components/Label';

import localization from './InfoSummaryDetails.local';

export default function InfoSummaryDetails({
  cities, citiesInfo, district,
}) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      {console.log(citiesInfo)}
      <Typography variant="h6">{district ? `${local.district} ${district.name}` : local.state}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Label label={local.cities} value={district ? cities.filter((item) => item.district === district.id).length : cities.length} />
        </Grid>
        <Grid item xs={12} md={6} />
      </Grid>
    </Paper>
  );
}

InfoSummaryDetails.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  citiesInfo: PropTypes.arrayOf(PropTypes.shape({})),
  district: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

InfoSummaryDetails.defaultProps = {
  cities: undefined,
  citiesInfo: undefined,
  district: undefined,
};
