import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';

export default function CalendarAppointmentFormResourceEditor({ resource, appointmentResources, ...restProps }) {
  const noRender = useMemo(() => !appointmentResources.find((item) => item.fieldName === resource.fieldName), [resource, appointmentResources]);

  if (noRender) {
    return <div />;
  }

  return (
    <AppointmentForm.ResourceEditor
      {...restProps}
      resource={resource}
      appointmentResources={appointmentResources}
      readOnly
    />
  );
}

CalendarAppointmentFormResourceEditor.propTypes = {
  resource: PropTypes.shape({
    fieldName: PropTypes.string.isRequired,
  }).isRequired,
  appointmentResources: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
