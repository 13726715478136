import React from 'react';
import PropTypes from 'prop-types';
import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';

import CalendarAppointmentFormCommandButton from './CalendarAppointmentFormCommandButton';

export default function CalendarAppointmentFormCommandLayout({
  disableSaveButton,
  hideDeleteButton,
  ...restProps
}) {
  return (
    <AppointmentForm.CommandLayout
      {...restProps}
      disableSaveButton={disableSaveButton}
      hideDeleteButton={hideDeleteButton}
      commandButtonComponent={CalendarAppointmentFormCommandButton}
    />
  );
}

CalendarAppointmentFormCommandLayout.propTypes = {
  hideDeleteButton: PropTypes.bool.isRequired,
  disableSaveButton: PropTypes.bool.isRequired,
};
