import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';

import { LocalizationContext } from '../../../../AppContext';

import localization from './InfoSummarySettings.local';

const TYPE = {
  DROPDOWN: 'DROPDOWN',
  BOOLEAN: 'BOOLEAN',
  NUMBER: 'NUMBER',
};

export default function InfoSummarySettings({ citysettingdefinitions }) {
  const local = localization[useContext(LocalizationContext)];

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{local.settings}</Typography>
      <Masonry columns={{ xs: 1, md: 2 }} sx={{ width: '100%' }}>
        {citysettingdefinitions && citysettingdefinitions
          .filter((item) => item.value)
          .sort((a, b) => a.sort - b.sort)
          .map((definition) => (
            <Box key={definition.id}>
              <Typography variant="caption" sx={{ color: 'primary.main' }}>{definition.name}</Typography>
              { definition.type === TYPE.BOOLEAN && (
                <Box sx={{ ml: 2 }} display="flex" gap={5}>
                  <Typography variant="caption">{`${local.true}: ${definition.value.true}`}</Typography>
                  <Typography paragraph variant="caption">{`${local.false}: ${definition.value.false}`}</Typography>
                  { definition.value.unset > 0 && (
                    <Typography paragraph variant="caption">{`${local.unset}: ${definition.value.unset}`}</Typography>
                  )}
                </Box>
              )}
              { definition.type === TYPE.NUMBER && (
                <Box sx={{ ml: 2 }}>
                  <Typography variant="caption">{` ${definition.value}`}</Typography>
                </Box>
              )}
              { definition.type === TYPE.DROPDOWN && Object.keys(definition.value).map((key) => (
                <Box key={key} sx={{ ml: 2 }}>
                  <Typography variant="caption">{`${key}: ${definition.value[key]}`}</Typography>
                </Box>
              ))}
            </Box>
          ))}
      </Masonry>
    </Paper>
  );
}

InfoSummarySettings.propTypes = {
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
};

InfoSummarySettings.defaultProps = {
  citysettingdefinitions: undefined,
};
