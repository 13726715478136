import moment from 'moment';

export default ({
  event, city, district, archive,
}) => {
  // if (event.city === null && event.district === null) {
  //   return true;
  // }
  const isDistrict = !district || district.id === event.district;
  const isCity = !city || city.id === event.city;
  const isArchive = archive || moment(event.startDate).isAfter(moment().subtract(1, 'days'));
  return isDistrict && isCity && isArchive;
};
