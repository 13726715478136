export default {
  de: {
    district: 'Bezirk',
    gkz: 'GKZ',
    name: 'Gemeinde',
    status: 'Status',
    highestOffice: 'Höchstes Amt',
    absolute: 'Absolute Mehrheit',
    coalition: 'Koalition',
    mandates: 'Mandate',
    mandatesSpo: 'SPÖ Mandate',
    mandatesSpoPercent: 'SPÖ Mandate %',
    mandatesOvp: 'ÖVP Mandate',
    mandatesOvpPercent: 'ÖVP Mandate %',
    mandatesFpo: 'FPÖ Mandate',
    mandatesFpoPercent: 'FPÖ Mandate %',
    officials: 'SPÖ GR',
    officialsFemale: 'SPÖ GR Frauen',
    officialsFemalePercent: 'GR Frauen %',
    citizens: 'Bevölkerung',
    members: 'Mitglieder',
    membersAverageAge: 'Mitglieder Ø-Alter',
    officialsAverageAge: 'GR*Innen Ø-Alter',
    mayorName: 'BGM*In Name',
    mayorParty: 'BGM*In Partei',
    mayorBirthdate: 'BGM*In Geb.',
    mayorEmail: 'BGM*In Email',
    mayorPhone: 'BGM*In Telefon',
    mayorMail: 'BGM*In Post',
    mayorSince: 'BGM*In Seit',
    vicemayorName: 'VBGM*In Name',
    vicemayorParty: 'VBGM*In Partei',
    vicemayorBirthdate: 'VBGM*In Geb.',
    vicemayorEmail: 'VBGM*In Email',
    vicemayorPhone: 'VBGM*In Telefon',
    vicemayorMail: 'VBGM*In Post',
    vicemayorSince: 'VBGM*In Seit',
    noHighestOffice: '-',
    noAbsolute: '-',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};
