import React from 'react';
import PropTypes from 'prop-types';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, Button, Paper } from '@mui/material';

import { LocalizationContext } from '../../../../AppContext';
import CustomToolbar from '../../../../components/CustomToolbar';
import ColumnDefinition from './SwingViewColumnDefinition';
import InitialState from './SwingViewInitialState';
import Mapper from './SwingViewMapper';
import localization from './SwingView.local';

const MODE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

export default function SwingView({
  swingConfig,
  elections,
  cities,
  districts,
  parties,
  // presidents,
  onChange,
}) {
  const local = localization[React.useContext(LocalizationContext)];
  const [rows, setRows] = React.useState();
  const [columns, setColumns] = React.useState();
  const [initialState, setInitialState] = React.useState();

  React.useEffect(() => {
    setColumns(ColumnDefinition(local, swingConfig));
    setInitialState(InitialState(swingConfig));
  }, [swingConfig]);

  React.useEffect(() => {
    if (swingConfig && elections && parties && cities && districts) {
      setRows(Mapper({
        local, swingConfig, elections, parties, cities, districts,
      }));
    }
  }, [swingConfig, elections, cities, districts]);

  return (
    <Paper sx={{
      p: 2, pt: 5, position: 'relative', mb: 2, height: '100%',
    }}
    >
      <Box display="float" sx={{ position: 'absolute', top: 2, left: 2 }}>
        <Button onClick={() => onChange('mode', MODE.EDIT)}>{local.config}</Button>
      </Box>
      { columns && rows && initialState && (
        <DataGridPro
          sx={{ height: '100%', width: '100%' }}
          rows={rows}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          initialState={initialState}
        />
      )}
    </Paper>
  );
}

SwingView.propTypes = {
  swingConfig: PropTypes.shape({}).isRequired,
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  // presidents: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
};

SwingView.defaultProps = {
  elections: undefined,
  cities: undefined,
  districts: undefined,
  parties: undefined,
// presidents: undefined,
};
