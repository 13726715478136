import moment from 'moment';

export const booleanValueFormatter = (cell) => {
  if (cell.value === '1') {
    return 'Ja';
  }
  if (cell.value === '0') {
    return 'Nein';
  }
  return '';
};
export const numberValueFormatter = (cell) => (cell.value ? cell.value.toLocaleString() : 0);
export const percentValueFormatter = (cell) => `${cell.value ? cell.value.toLocaleString() : 0} %`;
export const dateTimeValueFormatter = (cell) => (cell.value ? moment(cell.value).format('DD.MM.YYYY, HH:mm') : undefined);
export const dateValueFormatter = (cell) => (cell.value ? moment(cell.value).format('DD.MM.YYYY') : undefined);
export const timeValueFormatter = (cell) => (cell.value ? moment(cell.value).format('HH:mm') : undefined);
export const userValueFormatter = (cell) => `${cell.value.firstname} ${cell.value.lastname}`;
