import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

import { UserContext } from '../../AppContext';
import Profile from './profile/Profile';
import Gemo from './gemo/Gemo';
import Settings from './settings/Settings';
import AuthenticatedBar from './AuthenticatedBar';

export default function Authenticated({
  theme, onLogout, onUpdateUser, onThemeChange,
}) {
  const user = useContext(UserContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <AuthenticatedBar theme={theme} onLogout={onLogout} onThemeChange={onThemeChange} />
      </Grid>
      <Grid item xs={12}>
        <Routes>
          <Route path="/profile" element={(<Profile onUpdateUser={onUpdateUser} />)} />
          <Route path="/:districtid/:cityid/*" element={<Gemo />} />
          { user.admin && <Route path="/settings/*" element={<Settings onUpdateUser={onUpdateUser} />} />}
          <Route path="*" element={<Navigate to="/-/-" />} />
        </Routes>
      </Grid>
    </Grid>
  );
}

Authenticated.propTypes = {
  theme: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  onThemeChange: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};
