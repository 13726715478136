import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { SocketContext } from '../../../../AppContext';
import GemoContext from '../GemoContext';
import AgreementsPost from './AgreementsPost';
import AgreementsPatch from './AgreementsPatch';
import AgreementsTable from './AgreementsTable';
import get from '../../../../util/get';

export default function Agreements() {
  const navigate = useNavigate();
  const {
    cities, city, districts, district,
  } = useContext(GemoContext);
  const socket = useContext(SocketContext);
  const [agreementcategories, setAgreementcategories] = useState();
  const [agreements, setAgreements] = useState();
  const [agreementstatuses, setAgreementstatuses] = useState();
  const [gridviews, setGridviews] = useState();
  const [users, setUsers] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'agreementcategories', agreementcategories, setAgreementcategories);
    get(socket, onAlert, 'agreements', agreements, setAgreements);
    get(socket, onAlert, 'agreementstatuses', agreementstatuses, setAgreementstatuses);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
    get(socket, onAlert, 'users', users, setUsers);
  }, []);

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <AgreementsPost
            agreementcategories={agreementcategories}
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements`)}
            onSetAgreements={setAgreements}
          />
        )}
      />
      <Route
        path=":agreementid"
        element={(
          <AgreementsPatch
            agreementcategories={agreementcategories}
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements`)}
            onSetAgreements={setAgreements}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <AgreementsTable
            agreementcategories={agreementcategories}
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            users={users}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements/${id}`)}
            onAddClick={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/agreements/post`)}
            gridviews={gridviews}
            onSetGridviews={setGridviews}
          />
        )}
      />
    </Routes>
  );
}
