import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import FilesPost from './FilesPost';
import FilesPatch from './FilesPatch';
import FilesTable from './FilesTable';
import { SocketContext } from '../../../../AppContext';
import GemoContext from '../GemoContext';
import get from '../../../../util/get';

export default function Files() {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);

  const {
    cities, city, districts, district,
  } = useContext(GemoContext);
  const [files, setFiles] = useState();
  const [users, setUsers] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'files', files, setFiles);
    get(socket, onAlert, 'users', users, setUsers);
  }, []);

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <FilesPost
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            files={files}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files`)}
            onSetFiles={setFiles}
          />
        )}
      />
      <Route
        path=":fileid"
        element={(
          <FilesPatch
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            files={files}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files`)}
            onSetFiles={setFiles}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <FilesTable
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            files={files}
            users={users}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files/${id}`)}
            onAddClick={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/files/post`)}
          />
        )}
      />
    </Routes>
  );
}
