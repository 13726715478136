import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Servicecategories from './servicecategories/Servicecategories';
import Servicestatuses from './servicestatuses/Servicestatuses';
import { SocketContext } from '../../../../AppContext';
import get from '../../../../util/get';

export default function Services() {
  const socket = useContext(SocketContext);
  const [servicescategories, setServicescategories] = useState();
  const [services, setServices] = useState();
  const [servicesstatuses, setServicesstatuses] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'servicescategories', servicescategories, setServicescategories);
    get(socket, onAlert, 'services', services, setServices);
    get(socket, onAlert, 'servicesstatuses', servicesstatuses, setServicesstatuses);
  }, []);

  return (
    <Routes>
      <Route
        path="categories/*"
        element={(
          <Servicecategories
            servicescategories={servicescategories}
            services={services}
            onSetServicecategories={setServicescategories}
          />
        )}
      />
      <Route
        path="statuses/*"
        element={(
          <Servicestatuses
            services={services}
            servicesstatuses={servicesstatuses}
            onSetServicestatuses={setServicesstatuses}
          />
        )}
      />
      <Route path="*" element={<Navigate to="categories" />} />
    </Routes>
  );
}
