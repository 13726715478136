import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PieChart from '@mui/icons-material/PieChart';
import HomeWork from '@mui/icons-material/HomeWork';
import Home from '@mui/icons-material/Home';
import Assignment from '@mui/icons-material/Assignment';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
// import EventAvailable from '@mui/icons-material/EventAvailable';
import ChatIcon from '@mui/icons-material/Chat';
import Gavel from '@mui/icons-material/Gavel';
import Poll from '@mui/icons-material/Poll';
import Timelapse from '@mui/icons-material/Timelapse';
import Archive from '@mui/icons-material/Archive';
import CalendarToday from '@mui/icons-material/CalendarToday';
import HowToVote from '@mui/icons-material/HowToVote';

import { LocalizationContext, UserContext } from '../../../AppContext';
import hasReadAccess from '../../../util/hasReadAccess';
import localization from './GemoMenu.local';

export default function GemoMenu({ sx }) {
  const local = localization[useContext(LocalizationContext)];
  const user = useContext(UserContext);
  const location = useLocation().pathname.split('/');
  const navigate = useNavigate();

  const onChange = (e, value) => navigate(`/${location[1]}/${location[2]}/${value}`);

  return (
    <Paper sx={sx}>
      <Tabs
        scrollButtons
        allowScrollButtonsMobile
        value={location[3] || 'info'}
        onChange={onChange}
        variant="scrollable"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab value="info" label={local.info} icon={<PieChart />} />
        { location[1] === '-' && <Tab value="districts" label={local.districts} icon={<HomeWork />} />}
        { location[2] === '-' && <Tab value="cities" label={local.cities} icon={<Home />} />}
        { hasReadAccess(user, location[1], location[2]) && <Tab value="reports" label={local.reports} icon={<Assignment />} />}
        { hasReadAccess(user, location[1], location[2]) && <Tab value="agreements" label={local.agreements} icon={<AssignmentTurnedIn />} />}
        { hasReadAccess(user, location[1], location[2]) && <Tab value="messages" label={local.messages} icon={<ChatIcon />} />}
        {/* { hasReadAccess(user, location[1], location[2]) && <Tab value="services" label={local.services} icon={<EventAvailable />} />} */}
        { hasReadAccess(user, location[1], location[2]) && <Tab value="officials" label={local.officials} icon={<Gavel />} />}
        <Tab value="electionresults" label={local.electionresults} icon={<Poll />} />
        {/* <Tab value="elections" label={local.elections} icon={<Poll />} /> */}
        <Tab value="swing" label={local.swing} icon={<HowToVote />} />
        { hasReadAccess(user, location[1], location[2]) && <Tab value="timeline" label={local.timeline} icon={<Timelapse />} />}
        { hasReadAccess(user, location[1], location[2]) && <Tab value="files" label={local.files} icon={<Archive />} />}
        <Tab value="calendar" label={local.calendar} icon={<CalendarToday />} />
      </Tabs>
    </Paper>
  );
}

GemoMenu.propTypes = {
  sx: PropTypes.shape({}),
};

GemoMenu.defaultProps = {
  sx: undefined,
};
