import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import ColumnDefinition from './CalendarTableColumnDefinition';
import RowsMapper from './CalendarTableRowsMapper';
import CalendarTableToolbar from './CalendarTableToolbar';
import localization from './CalendarTable.local';

export default function CalendarTable({
  cities,
  districts,
  events,
  gridviews,
  onSetGridviews,
  onChangeMode,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [gridview, setGridview] = useState();
  const [alert, setAlert] = useState();
  const [archive, setArchive] = useState(false);

  useEffect(() => setColumns(ColumnDefinition(local)), []);
  useEffect(() => setRows(RowsMapper({
    cities, districts, events, archive,
  })), [cities, districts, events, archive]);

  const onChangeGridview = (selectedGridview) => setGridview(selectedGridview || {});

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? [...gridviews, payload] : [payload]);
      setGridview(payload);
    }
  };

  const onPostGridview = (grid, view, global) => {
    socket.emit('gridviews.post', {
      ...gridview, view, grid, global,
    }, acknowledgePost);
  };

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? gridviews.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      setGridview(payload);
    }
  };
  const onPatchGridview = (view, global) => {
    socket.emit('gridviews.patch', { ...gridview, view, global }, acknowledgePatch);
  };

  const acknowledgeDelete = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews.filter((item) => item.id !== payload.id));
      setGridview({});
    }
  };
  const onDeleteGridview = () => {
    socket.emit('gridviews.destroy', gridview, acknowledgeDelete);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows && (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          components={{ Toolbar: CalendarTableToolbar }}
          componentsProps={{
            toolbar: {
              gridview, gridviews, onChangeGridview, onPostGridview, onPatchGridview, onDeleteGridview, onChangeMode, archive, setArchive,
            },
          }}
          columnVisibilityModel={gridview?.columnVisibilityModel || {}}
          sortModel={gridview?.sortModel || []}
          filterModel={gridview?.filterModel || { items: [] }}
          onColumnVisibilityModelChange={(model) => setGridview({ ...gridview, columnVisibilityModel: model })}
          onSortModelChange={(model) => setGridview({ ...gridview, sortModel: model })}
          onFilterModelChange={(model) => setGridview({ ...gridview, filterModel: model })}
        />
      )}
    </Paper>
  );
}

CalendarTable.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  events: PropTypes.arrayOf(PropTypes.shape({})),
  gridviews: PropTypes.arrayOf(PropTypes.shape({})),
  onSetGridviews: PropTypes.func.isRequired,
  onChangeMode: PropTypes.func.isRequired,
};

CalendarTable.defaultProps = {
  cities: undefined,
  districts: undefined,
  events: undefined,
  gridviews: undefined,
};
