import React, { useContext, useEffect, useState } from 'react';
import { uuid } from 'uuidv4';
import { Paper } from '@mui/material';

import SwingConfig from './SwingConfig';
import SwingView from './SwingView';
import { SocketContext } from '../../../../AppContext';
import GemoContext from '../GemoContext';
import get from '../../../../util/get';

const MODE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

const SCALE = {
  STATE: 'STATE',
  DISTRICT: 'DISTRICT',
  CITY: 'CITY',
};

export default function Swing() {
  const socket = useContext(SocketContext);

  const { cities, districts } = useContext(GemoContext);
  const [elections, setElections] = useState();
  const [electiontypes, setElectiontypes] = useState();
  const [parties, setParties] = useState();
  const [presidents, setPresidents] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'elections', elections, setElections);
    get(socket, onAlert, 'electiontypes', electiontypes, setElectiontypes);
    get(socket, onAlert, 'parties', parties, setParties);
    get(socket, onAlert, 'presidents', presidents, setPresidents);
  }, []);

  const [swingConfig, setSwingConfig] = React.useState({
    mode: MODE.EDIT,
    scale: SCALE.STATE,
    elections: {
      [uuid()]: { name: undefined },
      [uuid()]: { name: undefined },
      [uuid()]: { name: undefined },
    },
  });

  const onChange = (field, value) => setSwingConfig({ ...swingConfig, [field]: value });
  const onElectionChange = (electionId, field, value) => setSwingConfig(
    {
      ...swingConfig,
      elections: {
        ...swingConfig.elections,
        [electionId]: {
          ...swingConfig.elections[electionId],
          [field]: value,
        },
      },
    },
  );

  const onElectionAdd = () => {
    const newSwingconfig = { ...swingConfig };
    newSwingconfig.elections[uuid()] = { name: undefined };
    setSwingConfig(newSwingconfig);
  };

  const onElectionDelete = (electionId) => {
    const newSwingConfig = { ...swingConfig };
    if (newSwingConfig.elections && newSwingConfig.elections[electionId]) {
      const { [electionId]: deletedProperty, ...remainingProperties } = newSwingConfig.elections;
      newSwingConfig.elections = remainingProperties;
      setSwingConfig(newSwingConfig);
    }
  };

  if (!cities || !districts || !elections || !electiontypes || !parties) {
    return <Paper sx={{ p: 1 }}>lädt..!</Paper>;
  }

  return swingConfig.mode === MODE.EDIT ? (
    <SwingConfig
      swingConfig={swingConfig}
      cities={cities}
      parties={parties}
      districts={districts}
      elections={elections}
      electiontypes={electiontypes}
      onChange={onChange}
      onElectionChange={onElectionChange}
      onElectionDelete={onElectionDelete}
      onElectionAdd={onElectionAdd}
    />
  ) : (
    <SwingView
      swingConfig={swingConfig}
      cities={cities}
      districts={districts}
      electiontypes={electiontypes}
      elections={elections}
      parties={parties}
      presidents={presidents}
      onChange={onChange}
    />
  );
}
