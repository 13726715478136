import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import {
  Box, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Tooltip, Typography,
} from '@mui/material';
import CakeIcon from '@mui/icons-material/Cake';
import CalendarToday from '@mui/icons-material/CalendarToday';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import More from '@mui/icons-material/More';
import moment from 'moment';

import CalendarContext from './CalendarContext';

export default function CalendarAppointmentContent({ data, ...restProps }) {
  const { districts, cities, onClickMoreEvent } = useContext(CalendarContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickEvent = (e, event) => {
    e.preventDefault();
    onClickMoreEvent(event);
    onClose();
  };

  if (data.type === 'group') {
    return (
      <Box>
        <Popover
          id="tooltip_popover"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuList>
            {!!anchorEl && data.events.map((event) => {
              const district = districts.find((item) => item.id === event.district);
              const city = cities.find((item) => item.id === event.city);

              return (
                <MenuItem key={event.object.id} onClick={(e) => onClickEvent(e, event)}>
                  <ListItemIcon>
                    {event.type.includes('official') && <CakeIcon fontSize="small" />}
                    {event.type.includes('agreement') && <AssignmentTurnedIn fontSize="small" />}
                    {event.type === 'event' && <CalendarToday fontSize="small" />}
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {event.type.includes('official') ? event.title.split('(')[0].trim() : event.title}
                    </Typography>
                    <Typography variant="caption">
                      {`
                      ${moment(event.startDate).format('HH:mm')} - 
                      ${moment(event.endDate).format('HH:mm')} 
                      ${city ? `${city.name} (` : ''}${district ? district.name : 'Burgenland'}${city ? ')' : ''}
                      `}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </Popover>
        <Box onClick={onClick}>
          <Appointments.AppointmentContent {...restProps} data={data}>
            <Box display="flex" alignItems="center">
              <More fontSize="small" />
              <Typography variant="caption" sx={{ ml: 0.5 }}>
                {data.type.includes('official') ? data.title.split('(')[0].trim() : data.title}
              </Typography>
            </Box>
          </Appointments.AppointmentContent>
        </Box>
      </Box>
    );
  }

  return (
    <Appointments.AppointmentContent {...restProps} data={data}>
      <Tooltip title={data.title}>
        <Box display="flex" alignItems="center">
          {data.type.includes('official') && <CakeIcon fontSize="small" />}
          {data.type.includes('agreement') && <AssignmentTurnedIn fontSize="small" />}
          {data.type === 'event' && <CalendarToday fontSize="small" />}
          <Typography variant="caption" sx={{ ml: 0.5 }}>
            {data.type.includes('official') ? data.title.split('(')[0].trim() : data.title}
          </Typography>
        </Box>
      </Tooltip>
    </Appointments.AppointmentContent>
  );
}

CalendarAppointmentContent.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    notes: PropTypes.string,
    district: PropTypes.number,
    city: PropTypes.number,
    events: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

CalendarAppointmentContent.defaultProps = {
  data: {},
};
