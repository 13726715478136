import React, { useState, useContext } from 'react';
import {
  useNavigate, Routes, Route, Navigate,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

import { LocalizationContext } from '../../../AppContext';
import Copyright from '../../../components/Copyright';

import Agreements from './agreements/Agreements';
import Citysettingdefinitions from './citysettingdefinitions/Citysettingdefinitions';
import Citysettings from './citysettings/Citysettings';
import Elections from './elections/Elections';
import Officials from './officials/Officials';
import Reports from './reports/Reports';
import Services from './services/Services';
import Users from './users/Users';
import SettingsMenu from './SettingsMenu';
import SettingsNavigator from './SettingsNavigator';
import Logs from './logs/Logs';
import localization from './Settings.local';

const DRAWER_SX = {
  width: 250,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 250,
    boxSizing: 'border-box',
  },
};
const SWIPEABLE_DRAWER_SX = {
  width: 250,
  '& .MuiDrawer-paper': { width: 250, boxSizing: 'border-box' },
};

export default function Settings({ onUpdateUser }) {
  const local = localization[useContext(LocalizationContext)];
  const [drawerToggle, toggleDrawer] = useState(false);
  const navigate = useNavigate();

  return (
    <Box display="flex">
      <Hidden lgDown>
        <Drawer sx={DRAWER_SX} anchor="left" variant="permanent">
          <Toolbar />
          <SettingsNavigator />
        </Drawer>
      </Hidden>
      <Hidden xlUp>
        <SwipeableDrawer open={drawerToggle} onClose={() => toggleDrawer(false)} onOpen={() => toggleDrawer(true)} sx={SWIPEABLE_DRAWER_SX}>
          <Toolbar />
          <SettingsNavigator />
        </SwipeableDrawer>
      </Hidden>
      <Container sx={{ pt: 10, height: '100vh', display: 'table' }} maxWidth={false}>
        <Box display="flex" alignItems="center">
          <Hidden lgUp>
            <IconButton onClick={() => toggleDrawer(true)}><MenuIcon /></IconButton>
            <Divider orientation="vertical" flexItem sx={{ mt: 1, mr: 1, mb: 1 }} />
          </Hidden>
          <Breadcrumbs>
            <Button onClick={() => navigate('/-/-')} size="small" color="primary">{process.env.REACT_APP_NAME}</Button>
            <Button onClick={() => navigate('/settings')} size="small" color="primary">{local.settings}</Button>
          </Breadcrumbs>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <SettingsMenu sx={{ display: 'table-row', height: 70 }} />
        <Box sx={{ display: 'table-row', height: '100%', mt: 1 }}>
          <Box sx={{ height: '100%', pt: 1 }}>
            <Routes>
              <Route path="users/*" element={<Users onUpdateUser={onUpdateUser} />} />
              <Route path="agreements/*" element={<Agreements />} />
              <Route path="citysettings/*" element={<Citysettings />} />
              <Route path="citysettingdefinitions/*" element={<Citysettingdefinitions />} />
              <Route path="elections/*" element={<Elections />} />
              <Route path="officials/*" element={<Officials />} />
              <Route path="reports/*" element={<Reports />} />
              <Route path="services/*" element={<Services />} />
              <Route path="logs/*" element={<Logs />} />
              <Route path="*" element={<Navigate to="users" />} />
            </Routes>
          </Box>
        </Box>
        <Copyright sx={{ display: 'table-row', height: 36 }} />
      </Container>
    </Box>
  );
}

Settings.propTypes = {
  onUpdateUser: PropTypes.func.isRequired,
};
