export default {
  de: {
    title: 'Neue Partei hinzufügen',
    name: 'Name',
    submit: 'Partei erstellen',
    color: 'Farbcode (HEX)',
    parent: 'gruppieren unter',
    back: 'Zurück',
    alerts: {
      200: () => 'Neue Partei wurde gespeichert!',
      400: () => 'Achtung! Die neue Partei konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};
