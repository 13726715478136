export default {
  de: {
    type: 'Typ',
    typeElection: 'Wahlen',
    typeCity: 'Orte',
    electiontype: 'Wahltyp',
    electiondate: 'Wahldatum',
    scale: 'Größenordnung',
    view: 'Berechnen',
    scaleState: 'Bundesland',
    scaleDistrict: 'Bezirk',
    scaleCity: 'Gemeinde',
    parties: 'Parteien',
    delete: 'Löschen',
    addElection: 'Wahl hinzufügen',
    alerts: {
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
      501: () => 'Es muss mindestens eine Partei ausgewählt werden!',
      502: () => 'Es muss mindestens eine Wahl ausgewählt werden!',
      503: () => 'Wahltypen nicht korrekt konfiguriert!',
    },
  },
};
