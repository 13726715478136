import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import moment from 'moment';

export default function CalendarAppointmentFormDateEditor({
  className, readOnly, value, onValueChange,
}) {
  const dateValue = useMemo(() => moment(value).format('YYYY-MM-DDTHH:mm'), [value]);

  const onChange = (e) => {
    onValueChange(e.target.value);
  };

  return (
    <TextField
      type="datetime-local"
      className={className}
      disabled={readOnly}
      value={dateValue}
      onChange={onChange}
    />
  );
}

CalendarAppointmentFormDateEditor.propTypes = {
  className: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
};
