import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import ColumnDefinition from './DistrictsColumnDefinition';
import RowsMapper from './DistrictsRowsMapper';
import localization from './Districts.local';
import DistrictsToolbar from './DistrictsToolbar';
import GemoContext from '../GemoContext';
import get from '../../../../util/get';
import Loading from '../../../../components/Loading';

export default function Districts() {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [gridview, setGridview] = useState();
  const [alert, setAlert] = useState();
  const navigate = useNavigate();

  const { cities, districts } = useContext(GemoContext);
  const [elections, setElections] = useState();
  const [officials, setOfficials] = useState();
  const [gridviews, setGridviews] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'elections', elections, setElections);
    get(socket, onAlert, 'officials', officials, setOfficials);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
  }, []);

  useEffect(() => {
    setColumns(ColumnDefinition(local));
  }, []);
  useEffect(() => {
    if (cities && districts && elections && officials) {
      setRows(RowsMapper(local, cities, districts, elections, officials));
    }
  }, [cities, districts, elections, officials]);

  const onChangeGridview = (selectedGridview) => setGridview(selectedGridview || {});

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews ? [...gridviews, payload] : [payload]);
      setGridview(payload);
    }
  };

  const onPostGridview = (grid, view, global) => {
    socket.emit('gridviews.post', {
      ...gridview, view, grid, global,
    }, acknowledgePost);
  };

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews ? gridviews.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      setGridview(payload);
    }
  };
  const onPatchGridview = (view, global) => {
    socket.emit('gridviews.patch', { ...gridview, view, global }, acknowledgePatch);
  };

  const acknowledgeDelete = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews.filter((item) => item.id !== payload.id));
      setGridview({});
    }
  };
  const onDeleteGridview = () => {
    socket.emit('gridviews.destroy', gridview, acknowledgeDelete);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows ? (
        <DataGridPro
          sx={{ height: '100%', width: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={(row) => navigate(`/${row.id}/-`)}
          components={{ Toolbar: DistrictsToolbar }}
          componentsProps={{
            toolbar: {
              gridview, gridviews, onChangeGridview, onPostGridview, onPatchGridview, onDeleteGridview,
            },
          }}
          columnVisibilityModel={gridview?.columnVisibilityModel || {}}
          sortModel={gridview?.sortModel || []}
          filterModel={gridview?.filterModel || { items: [] }}
          onColumnVisibilityModelChange={(model) => setGridview({ ...gridview, columnVisibilityModel: model })}
          onSortModelChange={(model) => setGridview({ ...gridview, sortModel: model })}
          onFilterModelChange={(model) => setGridview({ ...gridview, filterModel: model })}
        />
      ) : (
        <Loading sx={{ pt: 10 }} />
      )}
    </Paper>
  );
}
