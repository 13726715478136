import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';
import { LocalizationContext } from '../../../../AppContext';

import localization from './InfoCitySwing.local';
import { LabelPie } from '../../../../components/Label';

export default function InfoCitySwing({
  cityid, elections, parties, councilParties,
}) {
  const local = localization[useContext(LocalizationContext)];
  const [swingIndex, setSwingIndex] = useState();
  const [swingElectionNames, setSwingElectionNames] = useState([]);

  useEffect(() => {
    if (elections) {
      setSwingElectionNames(
        Array.from(
          new Set(
            elections
              .filter((item) => item.city === cityid)
              .map((item) => item.name || '')
              .sort((a, b) => parseInt(b.slice(-4), 10) - parseInt(a.slice(-4), 10)),
          ),
        ).slice(0, 3),
      );
    }
  }, [elections]);

  useEffect(() => {
    if (elections && parties && councilParties && swingElectionNames.length >= 1) {
      const swingElections = elections
        .filter((election) => election.city === cityid && swingElectionNames.includes(election.name))
        .map((election) => ({
          ...election,
          parties: election.parties.map((partyitem) => {
            const partyData = parties.find((party) => party.id === partyitem.party);
            return { ...partyitem, party: partyData.id };
          }),
        }));

      const result = {};
      const votesPercentageValues = {};
      swingElections.forEach((election) => {
        const { eligible } = election;
        result[`${election.id}-eligible`] = eligible;
        councilParties.forEach((party) => {
          const partyItem = election.parties.find((item) => item.party === party.id);
          const votes = partyItem?.votes || 0;
          result[`${party.id}-${election.id}-votes`] = votes;
          const partyVotesPercentage = parseFloat(((votes / eligible) * 100).toFixed(2));
          result[`${party.id}-${election.id}-votes-percentage`] = partyVotesPercentage;
          if (!result[`${party.id}-max-votes`] || result[`${party.id}-max-votes`] < votes) {
            result[`${party.id}-max-votes`] = votes;
          }
          if (!result[`${party.id}-min-votes`] || result[`${party.id}-min-votes`] > votes) {
            result[`${party.id}-min-votes`] = votes;
          }
          if (!votesPercentageValues[party.id]) {
            votesPercentageValues[party.id] = [];
          }
          votesPercentageValues[party.id].push(partyVotesPercentage);
        });
      });
      councilParties.forEach((party) => {
        const aveVotesPercField = `${party.id}-average-votes-percentage`;
        const averageSrohField = `${party.id}-average-sroh`;
        const maxVotesField = `${party.id}-max-votes`;
        const minVotesField = `${party.id}-min-votes`;
        const swingField = `${party.id}-swing`;

        const votesPercentageSum = votesPercentageValues[party.id].reduce((acc, num) => acc + num, 0);
        result[aveVotesPercField] = parseFloat((votesPercentageSum / votesPercentageValues[party.id].length).toFixed(2));

        const averageSrohValues = {};
        swingElections.forEach((election) => {
          const srohField = `${party.id}-${election.id}-sroh`;
          const votesPercentageField = `${party.id}-${election.id}-votes-percentage`;

          result[srohField] = parseFloat(Math.abs(result[votesPercentageField] - result[aveVotesPercField]).toFixed(2));
          if (!averageSrohValues[party.id]) {
            averageSrohValues[party.id] = [];
          }
          averageSrohValues[party.id].push(result[srohField]);
        });
        const averageSrohSum = averageSrohValues[party.id].reduce((acc, num) => acc + num, 0);
        result[averageSrohField] = parseFloat((averageSrohSum / averageSrohValues[party.id].length).toFixed(2));
        result[swingField] = parseFloat((((result[maxVotesField] - result[minVotesField]) / 100) * result[averageSrohField]).toFixed(2));
      });
      setSwingIndex(result);
    }
  }, [elections, parties, councilParties, swingElectionNames]);

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Typography variant="h6">{local.title}</Typography>
      <Box display="flex" flexWrap="wrap" gap={2}>
        { swingIndex && councilParties && councilParties.map((party) => (
          <Box key={party.id} flexGrow={1}>
            <LabelPie label={party.name} value={swingIndex[`${party.id}-swing`]} color={party.color} />
          </Box>
        ))}
      </Box>
      <Grid container spacing={2}>
        {swingElectionNames.map((item) => (
          <Grid key={item} item sx={12} md={4} textAlign="center">
            <Typography variant="caption">{item}</Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

InfoCitySwing.propTypes = {
  cityid: PropTypes.number.isRequired,
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  councilParties: PropTypes.arrayOf(PropTypes.shape({})),
};

InfoCitySwing.defaultProps = {
  elections: undefined,
  parties: undefined,
  councilParties: undefined,
};
