export default {
  de: {
    config: 'Bearbeiten',
    swing: 'Swing Index',
    eligible: 'Wahlberechtigt',
    votes: 'Stimmen',
    votespercentage: '%',
    averagevotespercentage: 'Durchschnitt %',
    sroh: 'Sroh',
    averagesroh: 'Durchschnitt Sroh',
    maxvotes: 'Max. Stimmen',
    minvotes: 'Min. Stimmen',
    valid: 'Gültig',
    invalid: 'Ungültig',
    delete: 'Löschen',
    alerts: {
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
    gkz: 'GKZ',
    name: 'Bezeichnung',
    status: 'Typ',
  },
};
