import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider,
} from '@mui/material';
import { Cancel, Delete, Save } from '@mui/icons-material';

export default function CalendarAppointmentFormCommandButton({
  id, getMessage, onExecute, ...restProps
}) {
  if (id === 'cancelButton') {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Button {...restProps} id={id} name={id} variant="contained" color="secondary" onClick={onExecute}>
          <Cancel />
          {getMessage('cancel')}
        </Button>
      </Box>
    );
  }
  if (id === 'deleteButton') {
    return (
      <>
        <Button {...restProps} id={id} name={id} variant="contained" color="secondary" onClick={onExecute}>
          <Delete />
          {getMessage('delete')}
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: 1, ml: 1 }} />
      </>
    );
  }
  if (id === 'saveButton') {
    return (
      <Button {...restProps} id={id} name={id} variant="contained" color="primary" onClick={onExecute}>
        <Save />
        {getMessage('commitCommand')}
      </Button>
    );
  }
}

CalendarAppointmentFormCommandButton.propTypes = {
  id: PropTypes.string.isRequired,
  getMessage: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
};
