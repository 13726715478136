export default ({ agreement }) => ({
  title: agreement.description,
  startDate: `${agreement.until}T00:00`,
  endDate: `${agreement.until}T23:59`,
  allDay: true,
  type: `agreement_${agreement.status}`,
  district: agreement.district,
  city: agreement.city,
  object: agreement,
});
