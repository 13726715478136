import React, {
  useContext, useState, useEffect, useMemo,
} from 'react';
import {
  useNavigate, useLocation, Routes, Route, Navigate,
} from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

import { SocketContext, UserContext } from '../../../AppContext';
import Copyright from '../../../components/Copyright';
import hasReadAccess from '../../../util/hasReadAccess';

import GemoContext from './GemoContext';
import Agreements from './agreements/Agreements';
import Calendar from './calendar/Calendar';
import Cities from './cities/Cities';
import Districts from './districts/Districts';
import Elections from './elections/Elections';
import Electionresults from './electionresults/Electionresults';
import Info from './info/Info';
import Messages from './messages/Messages';
import Officials from './officials/Officials';
import Reports from './reports/Reports';
import Services from './services/Services';
import Swing from './swing/Swing';
import Timelines from './timelines/Timelines';
import Files from './files/Files';
import GemoMenu from './GemoMenu';
import GemoNavigator from './GemoNavigator';
import get from '../../../util/get';

const DRAWER_SX = {
  width: 250,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 250,
    boxSizing: 'border-box',
  },
};

export default function Gemo() {
  const user = useContext(UserContext);
  const socket = useContext(SocketContext);
  const [drawerToggle, toggleDrawer] = useState(false);
  const [cities, setCities] = useState();
  const [city, setCity] = useState();
  const [districts, setDistricts] = useState();
  const [district, setDistrict] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  path.shift();
  const districtid = path.shift();
  const cityid = path.shift();
  const menu = path.join('/');
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'cities', cities, setCities);
    get(socket, onAlert, 'districts', districts, setDistricts);
  }, []);

  useEffect(() => setDistrict(districts && districtid ? districts.find((item) => item.id === parseInt(districtid, 10)) : undefined), [districts, districtid]);
  useEffect(() => setCity(cities && cityid ? cities.find((item) => item.id === parseInt(cityid, 10)) : undefined), [cities, cityid]);

  const contextValue = useMemo(() => ({
    cities, city, districts, district,
  }), [cities, city, districts, district]);

  return (
    <Box display="flex">
      <GemoContext.Provider value={contextValue}>
        <Hidden lgDown>
          <Drawer anchor="left" variant="permanent" sx={DRAWER_SX}>
            <Toolbar />
            <GemoNavigator />
          </Drawer>
        </Hidden>
        <Hidden xlUp>
          <SwipeableDrawer
            open={drawerToggle}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
            sx={{
              width: 250,
              '& .MuiDrawer-paper': { width: 250, boxSizing: 'border-box' },
            }}
          >
            <Toolbar />
            <GemoNavigator />
          </SwipeableDrawer>
        </Hidden>
        <Container sx={{ pt: 8, height: '100vh', display: 'table' }} maxWidth={false}>
          <Box display="flex" alignItems="center">
            <Hidden lgUp>
              <IconButton onClick={() => toggleDrawer(true)}><MenuIcon /></IconButton>
              <Divider orientation="vertical" flexItem sx={{ mt: 1, mr: 1, mb: 1 }} />
            </Hidden>
            <Breadcrumbs>
              <Button onClick={() => navigate(`/-/-/${menu}`)} size="small" color={district || city ? undefined : 'primary'}>{process.env.REACT_APP_NAME}</Button>
              { district && <Button onClick={() => navigate(`/${districtid}/-/${menu}`)} size="small" color={city ? undefined : 'primary'}>{district.name}</Button>}
              { city && <Button onClick={() => navigate(`/${districtid}/${cityid}/${menu}`)} size="small" color="primary">{city.name}</Button>}
            </Breadcrumbs>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <GemoMenu sx={{ display: 'table-row', height: 70 }} />
          <Box sx={{ display: 'table-row', height: '100%', mt: 1 }}>
            <Box sx={{ height: '100%', pt: 1 }}>
              <Routes>
                { !district && <Route path="districts/*" element={<Districts />} /> }
                { !city && <Route path="cities/*" element={<Cities />} /> }
                { hasReadAccess(user, districtid, cityid) && (
                <>
                  <Route path="agreements/*" element={<Agreements />} />
                  <Route path="messages/*" element={<Messages />} />
                  <Route path="officials/*" element={<Officials />} />
                  <Route path="reports/*" element={<Reports />} />
                  <Route path="services/*" element={<Services />} />
                  <Route path="swing/*" element={<Swing />} />
                  <Route path="timeline/*" element={<Timelines />} />
                  <Route path="files/*" element={<Files />} />
                </>
                )}
                <Route path="info/*" element={<Info />} />
                <Route path="elections/*" element={<Elections />} />
                <Route path="electionresults/*" element={<Electionresults />} />
                <Route path="calendar/*" element={<Calendar />} />
                <Route path="*" element={<Navigate to="info" />} />
              </Routes>
            </Box>
          </Box>
          <Copyright sx={{ display: 'table-row', height: 36 }} />
        </Container>
      </GemoContext.Provider>
    </Box>
  );
}
