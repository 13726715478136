import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Home from '@mui/icons-material/Home';
import Assignment from '@mui/icons-material/Assignment';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import EventAvailable from '@mui/icons-material/EventAvailable';
import ChatIcon from '@mui/icons-material/Chat';
import Archive from '@mui/icons-material/Archive';

import { Button } from '@mui/material';
import { LocalizationContext, SocketContext } from '../../../../AppContext';
import localization from './Timelines.local';
import GemoContext from '../GemoContext';
import get from '../../../../util/get';
import Loading from '../../../../components/Loading';

const EXPAND_ELEMENTS = 10;
const SX_TIMELINEITEM = {};
const SX_TIMELINEITEM_HOVER = { cursor: 'pointer', backgroundColor: 'action.hover' };

export default function Timelines() {
  const local = localization[useContext(LocalizationContext)];
  const [values, setValues] = useState();
  const [hover, setHover] = useState();
  const [expandCounter, setExpandCounter] = useState(1);
  const navigate = useNavigate();
  const socket = useContext(SocketContext);

  const {
    cities, city, districts, district,
  } = useContext(GemoContext);
  const [agreements, setAgreements] = useState();
  const [citysettingdefinitions, setCitysettingdefinitions] = useState();
  const [citysettings, setCitysettings] = useState();
  const [files, setFiles] = useState();
  const [messages, setMessages] = useState();
  const [reports, setReports] = useState();
  const [reportdefinitions, setReportdefinitions] = useState();
  const [services, setServices] = useState();
  const [timelines, setTimelines] = useState();
  const [users, setUsers] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'agreements', agreements, setAgreements);
    get(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions);
    get(socket, onAlert, 'citysettings', citysettings, setCitysettings);
    get(socket, onAlert, 'files', files, setFiles);
    get(socket, onAlert, 'messages', messages, setMessages);
    get(socket, onAlert, 'reports', reports, setReports);
    get(socket, onAlert, 'reportdefinitions', reportdefinitions, setReportdefinitions);
    get(socket, onAlert, 'services', services, setServices);
    get(socket, onAlert, 'timelines', timelines, setTimelines);
    get(socket, onAlert, 'users', users, setUsers);
  }, []);

  useEffect(() => { setExpandCounter(1); }, [city, district]);

  useEffect(() => {
    if (agreements && cities && citysettingdefinitions && citysettings && districts && files && messages && reports && reportdefinitions && services && timelines && users) {
      setValues(
        timelines
          .filter((timeline) => (!district || district.id === timeline.district) && (!city || city.id === timeline.city))
          .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt), 'seconds'))
          .slice(0, EXPAND_ELEMENTS * expandCounter)
          .map((timeline) => {
            let caption;
            let link;
            if (timeline.namespace === 'agreements') {
              const agreement = agreements.find((item) => item.id === timeline.item);
              caption = agreement ? agreement.description : undefined;
              link = agreement ? `/${agreement.district}/${agreement.city}/${timeline.namespace}/${agreement.id}` : undefined;
            } else if (timeline.namespace === 'citysettings') {
              const citysetting = citysettings.find((item) => item.id === timeline.item);
              if (citysetting) {
                const citysettingdefinition = citysettingdefinitions.find((item) => item.id === citysetting.definition);
                let { value } = citysetting;
                if (citysettingdefinition?.type === 'DROPDOWN') {
                  const option = citysettingdefinition.options.find((item) => (item.id === citysetting.value) || (item.id === parseInt(citysetting.value, 10)));
                  value = option ? option.name : citysetting.value;
                }
                if (citysettingdefinition?.type === 'BOOLEAN') {
                  value = local[citysetting.value];
                }
                if (citysettingdefinition?.type === 'DATE') {
                  value = moment(citysetting.value).format('DD.MM.YYYY');
                }
                caption = `${citysettingdefinition?.name} - ${value}`;
                link = `/${citysetting.district}/${citysetting.city}/info`;
              }
            } else if (timeline.namespace === 'files') {
              const file = files.find((item) => item.id === timeline.item);
              if (file) {
                caption = file.name;
                link = `/${file.district}/${file.city}/${timeline.namespace}/${file.id}`;
              }
            } else if (timeline.namespace === 'messages') {
              const message = messages.find((item) => item.id === timeline.item);
              caption = message ? message.text : undefined;
              link = message ? `/${message.district}/${message.city}/${timeline.namespace}` : undefined;
            } else if (timeline.namespace === 'reports') {
              const report = reports.find((item) => item.id === timeline.item);
              if (report) {
                const reportDefinition = reportdefinitions.find((item) => item.id === report.definition);
                caption = reportDefinition?.name || 'Unbekannte Definition';
                link = `/${report.district}/${report.city}/${timeline.namespace}/${report.id}`;
              }
            }
            if (timeline.event === 'destroy') {
              caption = `${local[timeline.namespace]} ${local.destroy}`;
              link = `/${district ? district.id : '-'}/${city ? city.id : '-'}/${timeline.namespace}`;
            }
            if (!caption) {
              caption = `-${local.destroy}-`;
            }
            if (!link) {
              link = `/${district ? district.id : '-'}/${city ? city.id : '-'}/${timeline.namespace}`;
            }
            return {
              id: timeline.id,
              city: city || cities.find((item) => item.id === timeline.city),
              district: district || districts.find((item) => item.id === timeline.district),
              namespace: timeline.namespace,
              event: timeline.event,
              user: users.find((item) => item.id === timeline.user),
              caption,
              link,
              updatedAt: timeline.updatedAt,
            };
          }),
      );
    }
  }, [
    agreements,
    cities,
    city,
    citysettingdefinitions,
    citysettings,
    district,
    districts,
    files,
    messages,
    reports,
    reportdefinitions,
    services,
    timelines,
    expandCounter,
  ]);

  if (!values) {
    return (
      <Loading sx={{ pt: 10 }} />
    );
  }

  return (
    <Timeline position="right">
      { values.map((value) => (
        <TimelineItem
          key={value.id}
          onClick={() => navigate(value.link)}
          onMouseEnter={() => setHover(value.id)}
          onMouseLeave={() => setHover()}
          sx={hover === value.id ? SX_TIMELINEITEM_HOVER : SX_TIMELINEITEM}
        >
          <TimelineOppositeContent
            sx={{ m: 'auto 0' }}
            align="right"
            variant="body2"
            color="text.secondary"
          >
            <Typography>{`${value.user.firstname} ${value.user.lastname}`}</Typography>
            <Typography variant="caption" component="div">
              {`${district ? '' : `${value.district.name}, `}${city ? '' : `${value.city.name}`}`}
            </Typography>
            <Typography variant="caption">{moment(value.updatedAt).format('DD.MM.YYYY')}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="primary">
              { value.namespace === 'agreements' && (<AssignmentTurnedIn />)}
              { value.namespace === 'citysettings' && (<Home />)}
              { value.namespace === 'files' && (<Archive />)}
              { value.namespace === 'messages' && (<ChatIcon />)}
              { value.namespace === 'reports' && (<Assignment />)}
              { value.namespace === 'services' && (<EventAvailable />)}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" color="primary">
              {value.caption}
            </Typography>
            <Typography variant="caption">
              {`${local[value.namespace]} ${local[value.event]}`}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
      <Button fullWidth onClick={() => setExpandCounter(expandCounter + 1)}>Mehr</Button>
    </Timeline>
  );
}
