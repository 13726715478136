export default {
  de: {
    group: 'Parteien Gruppieren',
    district: 'Bezirk',
    city: 'Gemeinde',
    type: 'Typ',
    name: 'Name',
    date: 'Datum',
    eligible: 'Wahlberechtigte',
    votes: 'Stimmen',
    valid: 'Gültig',
    invalid: 'Ungültig',
  },
};
