import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import ReportsPost from './ReportsPost';
import ReportsPatch from './ReportsPatch';
import ReportsTable from './ReportsTable';
import { SocketContext } from '../../../../AppContext';
import GemoContext from '../GemoContext';
import get from '../../../../util/get';

export default function Reports() {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  const {
    cities, city, districts, district,
  } = useContext(GemoContext);
  const [citysettingdefinitions, setCitysettingdefinitions] = useState();
  const [citysettings, setCitysettings] = useState();
  const [reportdefinitions, setReportdefinitions] = useState();
  const [reports, setReports] = useState();
  const [reportstatuses, setReportstatuses] = useState();
  const [users, setUsers] = useState();
  const [gridviews, setGridviews] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions);
    get(socket, onAlert, 'citysettings', citysettingdefinitions, setCitysettings);
    get(socket, onAlert, 'reportdefinitions', citysettingdefinitions, setReportdefinitions);
    get(socket, onAlert, 'reports', citysettingdefinitions, setReports);
    get(socket, onAlert, 'reportstatuses', citysettingdefinitions, setReportstatuses);
    get(socket, onAlert, 'users', users, setUsers);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
  }, []);

  return (
    <Routes>
      <Route
        path="post"
        element={(
          <ReportsPost
            city={city}
            reportdefinitions={reportdefinitions}
            reports={reports}
            citysettingdefinitions={citysettingdefinitions}
            citysettings={citysettings}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports`)}
            onSetReports={setReports}
          />
        )}
      />
      <Route
        path=":reportid"
        element={(
          <ReportsPatch
            cities={cities}
            city={city}
            citysettingdefinitions={citysettingdefinitions}
            citysettings={citysettings}
            district={district}
            districts={districts}
            reportdefinitions={reportdefinitions}
            reports={reports}
            reportstatuses={reportstatuses}
            users={users}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports`)}
            onSetCitysettings={setCitysettings}
            onSetReports={setReports}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ReportsTable
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            reportdefinitions={reportdefinitions}
            reports={reports}
            reportstatuses={reportstatuses}
            users={users}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports/${id}`)}
            onAddClick={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/reports/post`)}
            gridviews={gridviews}
            onSetGridviews={setGridviews}
          />
        )}
      />
    </Routes>
  );
}
