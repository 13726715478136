import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';

import ColumnDefinition from './ElectionresultsTableColumnDefinition';
import RowsMapper from './ElectionresultsTableRowsMapper';
import Toolbar from './ElectionresultsTableToolbar';
import localization from './ElectionresultsTable.local';
import Loading from '../../../../components/Loading';

const ELECTIONTYPE_PRESIDENT = 5;

export default function ElectionresultsTable({
  cities,
  city,
  district,
  districts,
  elections,
  electiontypes,
  parties,
  presidents,
  onRowClick,
  gridviews,
  onSetGridviews,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [group, setGroup] = useState();
  const [electionresultsActive, setElectionresultsActive] = useState();
  const [partyColumns, setPartyColumns] = useState();
  const [partyRows, setPartyRows] = useState();
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [gridview, setGridview] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (elections && electiontypes && parties) {
      const newElectionresultsActive = elections.filter((item) => {
        const isCity = city ? city.id === item.city : true;
        const isDistrict = district ? district.id === item.district : true;
        const type = electiontypes.find((entry) => entry.id === item.type);
        return isCity && isDistrict && type.active;
      });
      const newPartyColumns = [];
      const newPartyRows = {};
      newElectionresultsActive.forEach((electionresult) => {
        if (!newPartyRows[electionresult.id]) {
          newPartyRows[electionresult.id] = {};
        }
        electionresult.parties.forEach((partyresult) => {
          let party;
          if (electionresult.type === ELECTIONTYPE_PRESIDENT && presidents) {
            const president = presidents.find((item) => item.id === partyresult.party);
            party = president.party ? parties.find((item) => item.id === president.party) : president;
          } else {
            party = parties.find((item) => item.id === partyresult.party);
          }
          const newParty = group && party.parent ? parties.find((item) => item.id === party.parent) : party;
          if (partyresult.votes > 0) {
            if (!newPartyColumns.includes(newParty.name)) {
              newPartyColumns.push(newParty.name);
            }
            if (newPartyRows[electionresult.id][newParty.name]) {
              newPartyRows[electionresult.id][newParty.name] += partyresult.votes;
            } else {
              newPartyRows[electionresult.id][newParty.name] = partyresult.votes;
            }
          }
        });
      });
      setElectionresultsActive(newElectionresultsActive);
      setPartyColumns(newPartyColumns);
      setPartyRows(newPartyRows);
    }
  }, [city, district, elections, electiontypes, presidents, group, parties]);

  useEffect(() => setColumns(ColumnDefinition(local, city, district, partyColumns)), [city, district, partyColumns]);
  useEffect(() => setRows(RowsMapper(
    local,
    cities,
    city,
    district,
    districts,
    electionresultsActive,
    electiontypes,
    partyRows,
  )), [
    cities,
    city,
    district,
    districts,
    electionresultsActive,
    electiontypes,
    partyRows,
  ]);

  const onChangeGridview = (selectedGridview) => setGridview(selectedGridview || {});

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? [...gridviews, payload] : [payload]);
      setGridview(payload);
    }
  };

  const onPostGridview = (grid, view, global) => {
    socket.emit('gridviews.post', {
      ...gridview, view, grid, global,
    }, acknowledgePost);
  };

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? gridviews.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      setGridview(payload);
    }
  };
  const onPatchGridview = (view, global) => {
    socket.emit('gridviews.patch', { ...gridview, view, global }, acknowledgePatch);
  };

  const acknowledgeDelete = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews.filter((item) => item.id !== payload.id));
      setGridview({});
    }
  };
  const onDeleteGridview = () => {
    socket.emit('gridviews.destroy', gridview, acknowledgeDelete);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows ? (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar }}
          componentsProps={{
            toolbar: {
              local,
              group,
              onSetGroup: setGroup,
              gridview,
              gridviews,
              onChangeGridview,
              onPostGridview,
              onPatchGridview,
              onDeleteGridview,
            },
          }}
          columnVisibilityModel={gridview?.columnVisibilityModel || {}}
          sortModel={gridview?.sortModel || []}
          filterModel={gridview?.filterModel || { items: [] }}
          onColumnVisibilityModelChange={(model) => setGridview({ ...gridview, columnVisibilityModel: model })}
          onSortModelChange={(model) => setGridview({ ...gridview, sortModel: model })}
          onFilterModelChange={(model) => setGridview({ ...gridview, filterModel: model })}
        />
      ) : (
        <Loading sx={{ pt: 10 }} />
      )}
    </Paper>
  );
}

ElectionresultsTable.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  elections: PropTypes.arrayOf(PropTypes.shape({})),
  electiontypes: PropTypes.arrayOf(PropTypes.shape({})),
  parties: PropTypes.arrayOf(PropTypes.shape({})),
  presidents: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onSetGridviews: PropTypes.func.isRequired,
  gridviews: PropTypes.arrayOf(PropTypes.shape({})),
};

ElectionresultsTable.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  elections: undefined,
  electiontypes: undefined,
  parties: undefined,
  presidents: undefined,
  gridviews: undefined,
};
