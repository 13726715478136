export default {
  de: {
    state: 'Bundesland',
    district: 'Bezirk / Region',
    cities: 'Gemeinden',
    gkz: 'GKZ',
    highestOffice: 'Höchstes Amt',
    members: 'Mitglieder',
    membersAverageAge: 'Mitglieder Ø-Alter',
    officials: 'SPÖ Gemeinderäte*rätinnen',
    officialsAverageAge: 'SPÖ Gemeinderat*rätin Ø-Alter',
    officialsFemale: 'SPÖ Gemeinderätinnen',
    officialsFemalePercent: 'SPÖ-GR Frauenanteil',
    officialsMale: 'SPÖ Gemeinderäte',
    officialsMalePercent: 'SPÖ-GR Männeranteil',
  },
};
