import { dateValueFormatter, timeValueFormatter } from '../../../../util/formatter';

export default function CalendarTableColumnDefinition(local) {
  return [
    {
      type: 'string',
      field: 'district',
      headerName: local.district,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'string',
      field: 'city',
      headerName: local.city,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'string',
      field: 'type',
      headerName: local.type,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'string',
      field: 'title',
      headerName: local.title,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'date',
      field: 'startDate',
      headerName: local.startDate,
      valueFormatter: dateValueFormatter,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'string',
      field: 'startTime',
      headerName: local.startTime,
      valueFormatter: timeValueFormatter,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'date',
      field: 'endDate',
      headerName: local.endDate,
      valueFormatter: dateValueFormatter,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'string',
      field: 'endTime',
      headerName: local.endTime,
      valueFormatter: timeValueFormatter,
      minWidth: 100,
      flex: 1,
    }, {
      type: 'string',
      field: 'notes',
      headerName: local.notes,
      minWidth: 100,
      flex: 1,
    }];
}
