import React, { useState, useEffect, useContext } from 'react';

import InfoCity from './InfoCity';
import infoCityMapping from './infoCityMapping';
import InfoSummary from './InfoSummary';
import infoSummaryMapping from './infoSummaryMapping';
import get from '../../../../util/get';
import { SocketContext } from '../../../../AppContext';
import GemoContext from '../GemoContext';

export default function Info() {
  const [citiesInfo, setCitiesInfo] = useState();
  const [group, setGroup] = useState(true);
  const socket = useContext(SocketContext);
  const { cities, city, district } = useContext(GemoContext);

  const [agreements, setAgreements] = useState();
  const [agreementstatuses, setAgreementstatuses] = useState();
  const [citysettingdefinitions, setCitysettingdefinitions] = useState();
  const [citysettings, setCitysettings] = useState();
  const [elections, setElections] = useState();
  const [officials, setOfficials] = useState();
  const [parties, setParties] = useState();
  const [reportdefinitions, setReportdefinitions] = useState();
  const [reports, setReports] = useState();
  const [reportstatuses, setReportstatuses] = useState();
  const [users, setUsers] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'agreements', agreements, setAgreements);
    get(socket, onAlert, 'agreementstatuses', agreementstatuses, setAgreementstatuses);
    get(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions);
    get(socket, onAlert, 'citysettings', citysettings, setCitysettings);
    get(socket, onAlert, 'elections', elections, setElections);
    get(socket, onAlert, 'officials', officials, setOfficials);
    get(socket, onAlert, 'parties', parties, setParties);
    get(socket, onAlert, 'reportdefinitions', reportdefinitions, setReportdefinitions);
    get(socket, onAlert, 'reports', reports, setReports);
    get(socket, onAlert, 'reportstatuses', reportstatuses, setReportstatuses);
    get(socket, onAlert, 'users', users, setUsers);
  }, []);

  useEffect(() => {
    if (agreements && agreementstatuses && cities && citysettingdefinitions && elections && officials && parties && reportdefinitions && reports && reportstatuses) {
      setCitiesInfo(city && district
        ? [infoCityMapping(
          agreements,
          agreementstatuses,
          city,
          district,
          elections,
          group,
          officials,
          parties,
          reportdefinitions,
          reports,
          reportstatuses,
        )]
        : infoSummaryMapping(
          agreements,
          agreementstatuses,
          cities,
          citysettingdefinitions,
          citysettings,
          district,
          elections,
          group,
          officials,
          parties,
          reportdefinitions,
          reports,
          reportstatuses,
        ));
    }
  }, [
    agreements,
    agreementstatuses,
    cities,
    city,
    citysettings,
    citysettingdefinitions,
    district, elections,
    group,
    officials,
    parties,
    reportdefinitions,
    reports,
    reportstatuses,
  ]);

  return citiesInfo && citiesInfo.length === 1 ? (
    <InfoCity
      cityInfo={citiesInfo[0]}
      citysettingdefinitions={citysettingdefinitions}
      citysettings={citysettings}
      district={district}
      elections={elections}
      parties={parties}
      group={group}
      users={users}
      onSetCitysettings={setCitysettings}
      onSetGroup={setGroup}
      onSetElections={setElections}
    />
  ) : (
    <InfoSummary
      cities={cities}
      citiesInfo={citiesInfo}
      district={district}
      group={group}
      onSetGroup={setGroup}
    />
  );
}

Info.defaultProps = {
  agreements: undefined,
  agreementstatuses: undefined,
  cities: undefined,
  city: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  district: undefined,
  elections: undefined,
  officials: undefined,
  parties: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  users: undefined,
};
