import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Officialcategories from './officialcategories/Officialcategories';
import Officialtypes from './officialtypes/Officialtypes';
import { SocketContext } from '../../../../AppContext';
import get from '../../../../util/get';

export default function Officials() {
  const socket = useContext(SocketContext);
  const [officialcategories, setOfficialcategories] = useState();
  const [officials, setOfficials] = useState();
  const [officialtypes, setOfficialtypes] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'officialcategories', officialcategories, setOfficialcategories);
    get(socket, onAlert, 'officials', officials, setOfficials);
    get(socket, onAlert, 'officialtypes', officialtypes, setOfficialtypes);
  }, []);

  return (
    <Routes>
      <Route
        path="categories/*"
        element={(
          <Officialcategories
            officialcategories={officialcategories}
            officials={officials}
            onSetOfficialcategories={setOfficialcategories}
          />
        )}
      />
      <Route
        path="types/*"
        element={(
          <Officialtypes
            officials={officials}
            officialtypes={officialtypes}
            onSetOfficialtypes={setOfficialtypes}
          />
        )}
      />
      <Route path="*" element={<Navigate to="types" />} />
    </Routes>
  );
}
