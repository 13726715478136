import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Agreementcategories from './agreementcategories/Agreementcategories';
import Agreementstatuses from './agreementstatuses/Agreementstatuses';
import { SocketContext } from '../../../../AppContext';
import get from '../../../../util/get';

export default function Agreements() {
  const socket = useContext(SocketContext);
  const [agreementcategories, setAgreementcategories] = useState();
  const [agreements, setAgreements] = useState();
  const [agreementstatuses, setAgreementstatuses] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'agreementcategories', agreementcategories, setAgreementcategories);
    get(socket, onAlert, 'agreements', agreements, setAgreements);
    get(socket, onAlert, 'agreementstatuses', agreementstatuses, setAgreementstatuses);
  }, []);

  return (
    <Routes>
      <Route
        path="categories/*"
        element={(
          <Agreementcategories
            agreementcategories={agreementcategories}
            agreements={agreements}
            onSetAgreementcategories={setAgreementcategories}
          />
        )}
      />
      <Route
        path="statuses/*"
        element={(
          <Agreementstatuses
            agreements={agreements}
            agreementstatuses={agreementstatuses}
            onSetAgreementstatuses={setAgreementstatuses}
          />
        )}
      />
      <Route path="*" element={<Navigate to="categories" />} />
    </Routes>
  );
}
