import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import get from '../../../../util/get';
import UsersPost from './UsersPost';
import UsersPatch from './UsersPatch';
import UsersTable from './UsersTable';
import localization from './Users.local';

export default function Users({ onUpdateUser }) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [users, setUsers] = useState();
  const [alert, setAlert] = useState();
  const navigate = useNavigate();
  const [cities, setCities] = useState();
  const [districts, setDistricts] = useState();
  const onAlert = (code, namespace, payload) => setAlert({ code, props: { namespace, payload } });

  useEffect(() => {
    get(socket, onAlert, 'users', users, setUsers);
    get(socket, onAlert, 'cities', cities, setCities);
    get(socket, onAlert, 'districts', districts, setDistricts);
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      <Snackbar alert={alert ? alert.code : undefined} alertProps={alert ? alert.props : undefined} local={local.alerts} onClose={() => setAlert()} />
      <Routes>
        <Route
          path="post"
          element={(
            <UsersPost
              cities={cities}
              districts={districts}
              users={users}
              onBack={() => navigate('/settings/users/')}
              onSetUsers={setUsers}
            />
              )}
        />
        <Route
          path=":userid"
          element={(
            <UsersPatch
              cities={cities}
              districts={districts}
              users={users}
              onBack={() => navigate('/settings/users/')}
              onSetUsers={setUsers}
              onUpdateUser={onUpdateUser}
            />
          )}
        />
        <Route
          path="/"
          element={(
            <UsersTable
              cities={cities}
              districts={districts}
              users={users}
              onRowClick={(id) => navigate(`/settings/users/${id}`)}
              onAddClick={() => navigate('/settings/users/post')}
            />
          )}
        />
      </Routes>
    </Box>

  );
}

Users.propTypes = {
  onUpdateUser: PropTypes.func.isRequired,
};
