import moment from 'moment';
import calendarEventsMap from './calendarEventsMap';
import calendarEventsFilter from './calendarEventsFilter';
import calendarEventsAgreementFilter from './calendarEventsAgreementFilter';
import calendarEventsAgreementMap from './calendarEventsAgreementMap';
import calendarEventsOfficialFilter from './calendarEventsOfficialFilter';
import calendarEventsOfficialMap from './calendarEventsOfficialMap';

export default ({
  agreements, agreementcategories, city, district, events, officials, showAgreements, showBgms, showVbgms, showOpvs, showOnlySpoe, archive,
}) => {
  const groupedEvents = [];
  const newEvents = [
    ...events
      .filter((event) => calendarEventsFilter({
        district, city, event, archive,
      }))
      .map((event) => calendarEventsMap({ event })),
  ];
  if (showAgreements) {
    newEvents.push(...agreements
      .filter((agreement) => calendarEventsAgreementFilter({
        agreement, district, city, agreementcategories, archive,
      }))
      .map((agreement) => calendarEventsAgreementMap({ agreement })));
  }
  if (showBgms || showVbgms || showOpvs) {
    const officialsToMap = {};
    officials
      .filter((official) => calendarEventsOfficialFilter({
        official, district, city, showOnlySpoe, showBgms, showVbgms, showOpvs,
      }))
      .forEach((official) => {
        if (!officialsToMap[official.personid]) {
          officialsToMap[official.personid] = official;
        } else if (officialsToMap[official.personid].type > official.type) {
          officialsToMap[official.personid] = official;
        }
      });
    newEvents.push(...Object.values(officialsToMap).map((official) => calendarEventsOfficialMap({ official })));
  }
  const newGroupEvents = {};
  const newGroupEventsCounter = {};
  newEvents.forEach((event) => {
    const date = event.type.includes('official') ? moment(event.startDate).format('2024-MM-DD') : moment(event.startDate).format('YYYY-MM-DD');
    const eventsOnThatDay = newEvents.filter((item) => moment(item.startDate).format('YYYY-MM-DD') === date
    || (item.type.includes('official') && moment(item.startDate).format('2024-MM-DD') === date));
    if (eventsOnThatDay.length > 3) {
      if (!newGroupEventsCounter[date]) {
        newGroupEventsCounter[date] = 0;
      }
      if (newGroupEventsCounter[date] >= 2) {
        if (!newGroupEvents[date]) {
          newGroupEvents[date] = {
            startDate: `${date}T23:58`,
            endDate: `${date}T23:59`,
            events: [],
            title: '0 weitere Events...',
            type: 'group',
          };
        }
        newGroupEventsCounter[date] += 1;
        newGroupEvents[date].events.push(event);
        newGroupEvents[date].title = `${newGroupEvents[date].events.length} weitere Events...`;
      } else {
        newGroupEventsCounter[date] += 1;
        groupedEvents.push(event);
      }
    } else {
      groupedEvents.push(event);
    }
  });
  groupedEvents.push(...Object.values(newGroupEvents));
  return [newEvents, groupedEvents];
};
