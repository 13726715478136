import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, UserContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import ColumnDefinition from './CitiesColumnDefinition';
import RowsMapper from './CitiesRowsMapper';
import CitiesToolbar from './CitiesToolbar';
import localization from './Cities.local';
import GemoContext from '../GemoContext';
import get from '../../../../util/get';
import Loading from '../../../../components/Loading';

export default function Cities() {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const { cities, districts, district } = useContext(GemoContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [gridview, setGridview] = useState();
  const [alert, setAlert] = useState();
  const navigate = useNavigate();

  const [citysettingdefinitions, setCitysettingdefinitions] = useState();
  const [citysettings, setCitysettings] = useState();
  const [elections, setElections] = useState();
  const [officials, setOfficials] = useState();
  const [gridviews, setGridviews] = useState();
  const [parties, setParties] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'citysettingdefinitions', citysettingdefinitions, setCitysettingdefinitions);
    get(socket, onAlert, 'citysettings', citysettings, setCitysettings);
    get(socket, onAlert, 'elections', elections, setElections);
    get(socket, onAlert, 'officials', officials, setOfficials);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
    get(socket, onAlert, 'parties', parties, setParties);
  }, []);

  useEffect(() => setColumns(ColumnDefinition(local, citysettingdefinitions, district, user)), [citysettingdefinitions, district, user]);
  useEffect(() => setRows(RowsMapper(
    local,
    cities,
    citysettingdefinitions,
    citysettings,
    districts,
    district,
    elections,
    officials,
    parties,
    user,
  )), [cities, citysettings, districts, district, elections, officials, parties, user]);

  const onChangeGridview = (selectedGridview) => setGridview(selectedGridview || {});

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews ? [...gridviews, payload] : [payload]);
      setGridview(payload);
    }
  };

  const onPostGridview = (grid, view, global) => {
    socket.emit('gridviews.post', {
      ...gridview, view, grid, global,
    }, acknowledgePost);
  };

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews ? gridviews.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      setGridview(payload);
    }
  };
  const onPatchGridview = (view, global) => {
    socket.emit('gridviews.patch', { ...gridview, view, global }, acknowledgePatch);
  };

  const acknowledgeDelete = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews.filter((item) => item.id !== payload.id));
      setGridview({});
    }
  };
  const onDeleteGridview = () => {
    socket.emit('gridviews.destroy', gridview, acknowledgeDelete);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows ? (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => navigate(`/${row.districtid}/${row.id}`)}
          components={{ Toolbar: CitiesToolbar }}
          componentsProps={{
            toolbar: {
              gridview, gridviews, onChangeGridview, onPostGridview, onPatchGridview, onDeleteGridview,
            },
          }}
          columnVisibilityModel={gridview?.columnVisibilityModel || {}}
          sortModel={gridview?.sortModel || []}
          filterModel={gridview?.filterModel || { items: [] }}
          onColumnVisibilityModelChange={(model) => setGridview({ ...gridview, columnVisibilityModel: model })}
          onSortModelChange={(model) => setGridview({ ...gridview, sortModel: model })}
          onFilterModelChange={(model) => setGridview({ ...gridview, filterModel: model })}
        />
      ) : (
        <Loading sx={{ pt: 10 }} />
      )}
    </Paper>
  );
}
