import cityMapper from './SwingViewMapperCities';
import districtMapper from './SwingViewMapperDistricts';
import stateMapper from './SwingViewMapperState';

export default function SwingViewRowsMapper({
  swingConfig,
  elections,
  parties,
  cities,
  districts,
}) {
  const cityRows = cityMapper({
    swingConfig,
    elections,
    parties,
    cities,
  });
  const districtRows = districtMapper({
    swingConfig,
    districts,
    cityRows,
  });
  return [
    stateMapper({ swingConfig, districtRows }),
    ...districtRows,
    ...cityRows,
  ];
}
