export default {
  de: {
    district: 'Bezirk',
    city: 'Gemeinde',
    type: 'Typ',
    title: 'Titel',
    startDate: 'Start (Datum)',
    startTime: 'Start (Zeit)',
    endDate: 'Ende (Datum)',
    endTime: 'Ende (Zeit)',
    notes: 'Weitere Informationen',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};
