import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import StepButton from '@mui/material/StepButton';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import RestoreIcon from '@mui/icons-material/Restore';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { LocalizationContext, SocketContext, UserContext } from '../../../../AppContext';
import hasSteeringAccess from '../../../../util/hasSteeringAccess';
import hasWriteAccess from '../../../../util/hasWriteAccess';
import Confirm from '../../../../components/Confirm';
import Snackbar from '../../../../components/Snackbar';
import Loading from '../../../../components/Loading';
import Label from '../../../../components/Label';
import ReportsPatchPdf from './ReportsPatchPdf';
import localization from './ReportsPatch.local';

export default function ReportsPatch({
  cities,
  citysettingdefinitions,
  citysettings,
  reportdefinitions,
  reports,
  reportstatuses,
  users,
  onBack,
  onSetCitysettings,
  onSetReports,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const { reportid } = useParams();
  const [report, setReport] = useState();
  const [status, setStatus] = useState();
  const [definition, setDefinition] = useState();
  const [date, setDate] = useState();
  const [creator, setCreator] = useState();
  const [editor, setEditor] = useState();
  const [document, setDocument] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [activeStep, setActiveStep] = useState();
  const [isWeb, setWeb] = useState(true);
  const [city, setCity] = useState();

  useEffect(() => {
    if (reports && reportid) {
      const foundReport = reports.find((item) => item.id === parseInt(reportid, 10));
      if (foundReport) {
        setReport(foundReport);
      } else {
        onBack();
      }
    }
  }, [reports, reportid]);

  useEffect(() => {
    if (cities && report) {
      setCity(cities.find((item) => item.id === report.city));
    }
  }, [cities, report]);

  useEffect(() => {
    if (report && users) {
      setStatus(reportstatuses.find((item) => item.id === report.status));
      setDefinition(reportdefinitions.find((item) => item.id === report.definition));
      setDate(report.date);
      setCreator(users.find((item) => item.id === report.creator));
      setEditor(users.find((item) => item.id === report.editor));
      socket.emit('reports.getDocument', { id: report.id, city: report.city, district: report.district }, ({ error, payload }) => (error ? setAlert(error) : setDocument(payload)));
      // socket.emit('users.getOneById', { id: report.editor }, ({ error, payload }) => (error ? setAlert(error) : setEditor(payload)));
    }
  }, [report, users]);

  const acknowledgeChange = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetReports(reports ? reports.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
    }
  };

  const onChange = (field, value) => {
    const params = {
      id: report.id,
      city: report.city,
      district: report.district,
      field,
      value,
    };
    socket.emit('reports.patch', params, acknowledgeChange);
  };

  const onDocumentChange = (view, element, value) => {
    const documentValue = {
      ...document,
      [view]: document[view] ? { ...document[view], [element]: value } : { [element]: value },
    };
    onChange('document', documentValue);
  };

  const onDocumentTextChange = (view, element, value) => {
    const documentValue = {
      ...document,
      [view]: document[view] ? { ...document[view], [element]: value } : { [element]: value },
    };
    setDocument(documentValue);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange('document', documentValue), 1000));
  };

  const acknowledgeSettingsPatch = ({ error, payload }) => {
    if (!error) {
      onSetCitysettings(citysettings.map((item) => (item.id === payload.id ? payload : item)));
    }
  };

  const acknowledgeSettingsPost = ({ error, payload }) => {
    if (!error) {
      onSetCitysettings(citysettings ? [...citysettings, payload] : [payload]);
    }
  };

  const onSettingsChange = (view, element, setting, value) => {
    const citysetting = citysettings.find((item) => item.city === report.city && item.definition === setting);
    const params = {
      city: report.city,
      district: report.district,
      type: report.id,
      value,
    };
    if (citysetting) {
      params.id = citysetting.id;
      socket.emit('citysettings.patch', params, acknowledgeSettingsPatch);
    } else {
      params.definition = setting;
      socket.emit('citysettings.post', params, acknowledgeSettingsPost);
    }
    const documentValue = {
      ...document,
      [view]: document[view] ? { ...document[view], [element]: value } : { [element]: value },
    };
    onChange('document', documentValue);
  };

  const onSettingsTextChange = (view, element, setting, value) => {
    const documentValue = {
      ...document,
      [view]: document[view] ? { ...document[view], [element]: value } : { [element]: value },
    };
    setDocument(documentValue);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onSettingsChange(view, element, setting, value), 1000));
  };

  const onArchive = () => {
    onChange('active', !report.active);
    onBack();
  };

  const acknowledgeDestroy = ({ error, payload }) => {
    if (error) {
      setAlert(error);
    } else {
      onSetReports(reports ? reports.filter((item) => (item.id !== payload.id)) : []);
      onBack();
    }
  };

  const onDestroy = () => {
    const params = {
      id: report.id,
      city: report.city,
      district: report.district,
    };
    socket.emit('reports.destroy', params, acknowledgeDestroy);
  };

  if (!document) {
    return (
      <Loading />
    );
  }

  return (
    <Paper sx={{ height: '100%', p: 5 }}>
      <Confirm
        title={local.destroyTitle}
        description={local.destroyDescription}
        agree={local.agree}
        disagree={local.disagree}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={onDestroy}
      />
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Box display="flex">
        <Typography variant="h6">{local.title}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Label label={local.status} value={status ? status.name : ''} />
        <Label sx={{ ml: 2 }} label={local.editor} value={editor ? `${editor.firstname} ${editor.lastname}` : ''} />
        <Label sx={{ ml: 2 }} label={local.updatedAt} value={report ? moment(report.updatedAt).format('DD.MM.YYYY') : ''} />
        <Label sx={{ ml: 2 }} label={local.creator} value={creator ? `${creator.firstname} ${creator.lastname}` : ''} />
        <Label sx={{ ml: 2 }} label={local.date} value={moment(date).format('DD.MM.YYYY')} />
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      { isWeb ? (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Stepper nonLinear activeStep={activeStep} xs={{ width: '100%' }} orientation="vertical">
              { definition && definition.document.map((view, index) => (
                <Step key={view.key}>
                  <StepButton color="primary" onClick={() => setActiveStep(index)}>{view.name}</StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={10} sx={{ pl: 5 }}>
            { definition && definition.document[activeStep || 0] && (
              <Typography variant="h6" sx={{ mb: 2 }}>{definition.document[activeStep || 0].name}</Typography>
            )}
            { definition && definition.document[activeStep || 0].values.map((element) => {
              const view = definition.document[activeStep || 0];

              const setting = element.type === 'SETTING' ? element.definition : undefined;
              const settingdefinition = setting && citysettingdefinitions
                ? citysettingdefinitions.find((item) => item.id === parseInt(setting, 10))
                : undefined;
              const textFieldType = {
                TEXT: undefined,
                DATE: 'date',
                NUMBER: 'number',
              };
              return (
                <Box key={element.key} sx={{ mt: 1, mb: 5 }}>
                  <Typography sx={{ mb: 2, color: 'primary.main' }}>{element.text}</Typography>
                  {element.list && element.list.map((bullet) => (
                    <Box key={bullet} display="flex">
                      <Box sx={{
                        mr: 1, mt: 1, height: 5, width: 5, borderRadius: '25px', backgroundColor: 'primary.main',
                      }}
                      />
                      <Typography variant="caption">{bullet}</Typography>
                    </Box>
                  ))}
                  {['TEXT', 'NUMBER', 'DATE'].includes(element.type) && (
                    <TextField
                      fullWidth
                      multiline={element.type === 'TEXT'}
                      type={textFieldType[element.type]}
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      value={document[view.key] ? document[view.key][element.key] || '' : ''}
                      onChange={(e) => onDocumentTextChange(view.key, element.key, e.target.value)}
                      inputProps={
                          { readOnly: !hasWriteAccess(user, city.district, city.id) || report.status > 1 }
                        }
                    />
                  )}
                  {element.type === 'BOOLEAN' && (
                    <ButtonGroup variant="contained" color="secondary">
                      <Button
                        color={document[view.key] && (document[view.key][element.key] === true || parseInt(document[view.key][element.key], 10) === 1) ? 'primary' : 'secondary'}
                        onClick={!hasWriteAccess(user, city.district, city.id) || report.status > 1 ? undefined : () => onDocumentChange(view.key, element.key, '1')}
                      >
                        {local.true}
                      </Button>
                      <Button
                        color={document[view.key] && (document[view.key][element.key] === false || parseInt(document[view.key][element.key], 10) === 0) ? 'primary' : 'secondary'}
                        onClick={!hasWriteAccess(user, city.district, city.id) || report.status > 1 ? undefined : () => onDocumentChange(view.key, element.key, '0')}
                      >
                        {local.false}
                      </Button>
                    </ButtonGroup>
                  )}
                  {element.type === 'DROPDOWN' && (
                    <Autocomplete
                      disabled={!hasWriteAccess(user, city.district, city.id) || report.status > 1}
                      value={document[view.key] && document[view.key][element.key]
                        ? element.options.find((item) => item.id.toString() === document[view.key][element.key].toString())
                        : null}
                      options={element.options.sort((a, b) => b - a)}
                      onChange={(e, selected) => onDocumentChange(view.key, element.key, selected.id)}
                      getOptionLabel={((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          variant="outlined"
                          color="secondary"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                  {settingdefinition && ['TEXT', 'NUMBER', 'DATE'].includes(settingdefinition.type) && (
                    <TextField
                      fullWidth
                      multiline={element.type === 'TEXT'}
                      type={textFieldType[settingdefinition.type]}
                      variant="outlined"
                      color="secondary"
                      margin="dense"
                      value={document[view.key] ? document[view.key][element.key] || '' : ''}
                      onChange={(e) => onSettingsTextChange(view.key, element.key, settingdefinition.id, e.target.value)}
                      inputProps={
                        { readOnly: !hasWriteAccess(user, city.district, city.id) || report.status > 1 }
                      }
                    />
                  )}
                  {settingdefinition && settingdefinition.type === 'BOOLEAN' && (
                    <ButtonGroup variant="contained" color="secondary">
                      <Button
                        color={document[view.key] && (document[view.key][element.key] === true || parseInt(document[view.key][element.key], 10) === 1) ? 'primary' : 'secondary'}
                        onClick={!hasWriteAccess(user, city.district, city.id) || report.status > 1
                          ? undefined
                          : () => onSettingsChange(view.key, element.key, settingdefinition.id, '1')}
                      >
                        {local.true}
                      </Button>
                      <Button
                        color={document[view.key] && (document[view.key][element.key] === false || parseInt(document[view.key][element.key], 10) === 0) ? 'primary' : 'secondary'}
                        onClick={!hasWriteAccess(user, city.district, city.id) || report.status > 1
                          ? undefined
                          : () => onSettingsChange(view.key, element.key, settingdefinition.id, '0')}
                      >
                        {local.false}
                      </Button>
                    </ButtonGroup>
                  )}
                  {settingdefinition && settingdefinition.type === 'DROPDOWN' && (
                    <Autocomplete
                      disabled={!hasWriteAccess(user, city.district, city.id) || report.status > 1}
                      value={document[view.key] && document[view.key][element.key]
                        ? settingdefinition.options.find((item) => item.id.toString() === document[view.key][element.key].toString())
                        : null}
                      options={settingdefinition.options.sort((a, b) => b - a)}
                      onChange={(e, selected) => onSettingsChange(view.key, element.key, settingdefinition.id, selected.id)}
                      getOptionLabel={((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          variant="outlined"
                          color="secondary"
                          margin="dense"
                        />
                      )}
                    />
                  )}
                </Box>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        <ReportsPatchPdf
          city={city}
          citysettingdefinitions={citysettingdefinitions}
          definition={definition}
          report={report}
        />
      )}
      <Stack spacing={1} sx={{ position: 'fixed', right: 30, bottom: 40 }}>
        { report && ((!report.active && user.control) || (city && report.status === 1 && hasWriteAccess(user, city.district, city.id))) && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => setOpenConfirm(true)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography>{local.destroy}</Typography>
          </Fab>
        )}
        { city && reportstatuses && report && report.status === 1 && hasWriteAccess(user, city.district, city.id) && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={() => onChange('status', 2)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <Typography>{reportstatuses[1].name}</Typography>
          </Fab>
        )}
        { city && reportstatuses && report && report.status === 2 && hasSteeringAccess(user, city.district, city.id) && (
        <Fab
          variant="extended"
          color="primary"
          size="small"
          onClick={() => onChange('status', 3)}
          sx={{ justifyContent: 'flex-start' }}
        >
          <Typography>{reportstatuses[2].name}</Typography>
        </Fab>
        )}
        { city && reportstatuses && report && report.status === 2 && hasSteeringAccess(user, city.district, city.id) && (
        <Fab
          variant="extended"
          color="primary"
          size="small"
          onClick={() => onChange('status', 1)}
          sx={{ justifyContent: 'flex-start' }}
        >
          <Typography>{reportstatuses[0].name}</Typography>
        </Fab>
        )}
        { city && report && report.status === 3 && hasSteeringAccess(user, report.city, report.district) && (
          <Fab
            variant="extended"
            color="primary"
            size="small"
            onClick={onArchive}
            sx={{ justifyContent: 'flex-start' }}
          >
            {report.active ? <ArchiveIcon sx={{ mr: 1 }} /> : <RestoreIcon sx={{ mr: 1 }} />}
            <Typography>{report.active ? local.archive : local.restore}</Typography>
          </Fab>
        )}
        { city && reportstatuses && report && report.active && report.status === 3 && hasSteeringAccess(user, city.district, city.id) && (
        <Fab
          variant="extended"
          color="primary"
          size="small"
          onClick={() => onChange('status', 2)}
          sx={{ justifyContent: 'flex-start' }}
        >
          <Typography>{reportstatuses[1].name}</Typography>
        </Fab>
        )}
        <Fab
          variant="extended"
          color="primary"
          size="small"
          sx={{ justifyContent: 'flex-start' }}
          onClick={() => setWeb(!isWeb)}
        >
          <PictureAsPdfIcon sx={{ mr: 1 }} />
          <Typography>{isWeb ? local.pdfview : local.webview}</Typography>
        </Fab>
        <Fab
          variant="extended"
          color="secondary"
          size="small"
          onClick={onBack}
          sx={{ justifyContent: 'flex-start' }}
        >
          <CancelIcon sx={{ mr: 1 }} />
          <Typography>{local.back}</Typography>
        </Fab>
      </Stack>
    </Paper>
  );
}

ReportsPatch.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onBack: PropTypes.func.isRequired,
  onSetCitysettings: PropTypes.func.isRequired,
  onSetReports: PropTypes.func.isRequired,
};

ReportsPatch.defaultProps = {
  cities: undefined,
  citysettingdefinitions: undefined,
  citysettings: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  users: undefined,
};
