import React from 'react';
import PropTypes from 'prop-types';
import { createEvents } from 'ics';
import Button from '@mui/material/Button';

export default function DownloadIcsButton({ events }) {
  const convertEventsToIcs = () => {
    const icsEvents = events.map((event) => {
      const start = new Date(event.startDate);
      const end = new Date(event.endDate);

      const includeTime = !event.allDay;

      return {
        start: [
          start.getFullYear(),
          start.getMonth() + 1,
          start.getDate(),
          includeTime ? start.getHours() : null,
          includeTime ? start.getMinutes() : null,
        ].filter(Boolean),

        end: [
          end.getFullYear(),
          end.getMonth() + 1,
          end.getDate(),
          includeTime ? end.getHours() : null,
          includeTime ? end.getMinutes() : null,
        ].filter(Boolean),

        title: event.title,
        uid: `${event.title}-${start.toISOString()}`,
        description: event.notes,
        recurrenceRule: event.rRule?.replace('RRULE:', ''),
        startInputType: 'local',
        endInputType: 'local',
      };
    });

    return createEvents(icsEvents);
  };

  const downloadIcsFile = () => {
    const result = convertEventsToIcs();
    if (result.value) {
      const blob = new Blob([result.value], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'gemo-kalender.ics');
      document.body.appendChild(link);
      link.click();

      // Remember to revoke the blob URL to free up memory
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Button variant="outlined" sx={{ mr: 1 }} onClick={downloadIcsFile}>Export</Button>
  );
}

DownloadIcsButton.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

DownloadIcsButton.defaultProps = {
  events: [],
};
