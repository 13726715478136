import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import ElectionresultsPatch from './ElectionresultsPatch';
import ElectionresultsTable from './ElectionresultsTable';
import { SocketContext } from '../../../../AppContext';
import GemoContext from '../GemoContext';
import get from '../../../../util/get';

export default function Electionresults() {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);

  const {
    cities, city, districts, district,
  } = useContext(GemoContext);
  const [elections, setElections] = useState();
  const [electiontypes, setElectiontypes] = useState();
  const [parties, setParties] = useState();
  const [presidents, setPresidents] = useState();
  const [gridviews, setGridviews] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'elections', elections, setElections);
    get(socket, onAlert, 'electiontypes', electiontypes, setElectiontypes);
    get(socket, onAlert, 'parties', parties, setParties);
    get(socket, onAlert, 'presidents', presidents, setPresidents);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
  }, []);

  return (
    <Routes>
      <Route
        path=":electionresultid"
        element={(
          <ElectionresultsPatch
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            elections={elections}
            electiontypes={electiontypes}
            parties={parties}
            presidents={presidents}
            onBack={() => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/electionresults`)}
          />
        )}
      />
      <Route
        path="/"
        element={(
          <ElectionresultsTable
            cities={cities}
            city={city}
            district={district}
            districts={districts}
            elections={elections}
            electiontypes={electiontypes}
            parties={parties}
            presidents={presidents}
            onRowClick={(id) => navigate(`/${district ? district.id : '-'}/${city ? city.id : '-'}/electionresults/${id}`)}
            gridviews={gridviews}
            onSetGridviews={setGridviews}
          />
        )}
      />
    </Routes>
  );
}
