import React, { useContext, useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import get from '../../../../util/get';

import GemoContext from '../GemoContext';
import OfficialsToolbar from './OfficialsToolbar';
import ColumnDefinition from './OfficialsColumnDefinition';
import RowsMapper from './OfficialsRowsMapper';
import localization from './Officials.local';
import Loading from '../../../../components/Loading';

export default function Officials() {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [officialcategory, setOfficialcategory] = useState(1);
  const [gridview, setGridview] = useState();
  const [alert, setAlert] = useState();
  const {
    cities, city, districts, district,
  } = useContext(GemoContext);
  const [messages, setMessages] = useState();
  const [officialcategories, setOfficialcategories] = useState();
  const [officials, setOfficials] = useState();
  const [officialtypes, setOfficialtypes] = useState();
  const [parties, setParties] = useState();
  const [gridviews, setGridviews] = useState();
  const onAlert = () => {};

  useEffect(() => {
    get(socket, onAlert, 'messages', messages, setMessages);
    get(socket, onAlert, 'officialcategories', officialcategories, setOfficialcategories);
    get(socket, onAlert, 'officials', officials, setOfficials);
    get(socket, onAlert, 'officialtypes', officialtypes, setOfficialtypes);
    get(socket, onAlert, 'parties', parties, setParties);
    get(socket, onAlert, 'gridviews', gridviews, setGridviews);
  }, []);

  useEffect(() => setColumns(ColumnDefinition(local, city, district)), [city, district]);
  useEffect(() => setRows(RowsMapper(
    local,
    cities,
    city,
    districts,
    district,
    officialcategory,
    officials,
    officialtypes,
    parties,
  )), [cities, city, districts, district, officialcategory, officials, officialtypes, parties]);

  const onChangeGridview = (selectedGridview) => setGridview(selectedGridview || {});

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews ? [...gridviews, payload] : [payload]);
      setGridview(payload);
    }
  };

  const onPostGridview = (grid, view, global) => {
    socket.emit('gridviews.post', {
      ...gridview, view, grid, global,
    }, acknowledgePost);
  };

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews ? gridviews.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      setGridview(payload);
    }
  };
  const onPatchGridview = (view, global) => {
    socket.emit('gridviews.patch', { ...gridview, view, global }, acknowledgePatch);
  };

  const acknowledgeDelete = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      setGridviews(gridviews.filter((item) => item.id !== payload.id));
      setGridview({});
    }
  };
  const onDeleteGridview = () => {
    socket.emit('gridviews.destroy', gridview, acknowledgeDelete);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows ? (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          components={{ Toolbar: OfficialsToolbar }}
          componentsProps={{
            toolbar: {
              officialcategories,
              officialcategory,
              onSetOfficialcategory: setOfficialcategory,
              gridview,
              gridviews,
              onChangeGridview,
              onPostGridview,
              onPatchGridview,
              onDeleteGridview,
            },
          }}
          columnVisibilityModel={gridview?.columnVisibilityModel || {}}
          sortModel={gridview?.sortModel || []}
          filterModel={gridview?.filterModel || { items: [] }}
          onColumnVisibilityModelChange={(model) => setGridview({ ...gridview, columnVisibilityModel: model })}
          onSortModelChange={(model) => setGridview({ ...gridview, sortModel: model })}
          onFilterModelChange={(model) => setGridview({ ...gridview, filterModel: model })}
        />
      ) : (
        <Loading sx={{ pt: 10 }} />
      )}
    </Paper>
  );
}
