import { numberValueFormatter, percentValueFormatter } from '../../../../util/formatter';

export default function SwingViewColumnDefinition(local, swingConfig) {
  const columns = [{
    field: 'id',
    headerName: local.gkz,
    minWidth: 75,
    flex: 1,
  }, {
    field: 'name',
    headerName: local.name,
    minWidth: 200,
    flex: 3,
  }, {
    field: 'status',
    headerName: local.status,
    minWidth: 200,
    flex: 3,
  }];
  Object.entries(swingConfig.elections).forEach(([electionId, election]) => {
    columns.push({
      field: `${electionId}-eligible`,
      headerName: `${election.name} ${local.eligible}`,
      valueFormatter: numberValueFormatter,
      minWidth: 200,
      flex: 3,
    });
    swingConfig.parties.forEach((party) => {
      const partyName = `${election.name} ${party.name}`;
      columns.push({
        field: `${party.id}-${electionId}-votes`,
        headerName: `${partyName} ${local.votes}`,
        valueFormatter: numberValueFormatter,
        minWidth: 200,
        flex: 3,
      });
      columns.push({
        field: `${party.id}-${electionId}-votes-percentage`,
        headerName: `${partyName} ${local.votespercentage}`,
        valueFormatter: percentValueFormatter,
        minWidth: 200,
        flex: 3,
      });
      columns.push({
        field: `${party.id}-${electionId}-sroh`,
        headerName: `${partyName} ${local.sroh}`,
        valueFormatter: numberValueFormatter,
        minWidth: 200,
        flex: 3,
      });
    });
  });
  swingConfig.parties.forEach((party) => {
    columns.push({
      field: `${party.id}-average-votes-percentage`,
      headerName: `${party.name} ${local.averagevotespercentage}`,
      valueFormatter: percentValueFormatter,
      minWidth: 200,
      flex: 3,
    });
    columns.push({
      field: `${party.id}-average-sroh`,
      headerName: `${party.name} ${local.averagesroh}`,
      valueFormatter: numberValueFormatter,
      minWidth: 200,
      flex: 3,
    });
    columns.push({
      field: `${party.id}-max-votes`,
      headerName: `${party.name} ${local.maxvotes}`,
      valueFormatter: numberValueFormatter,
      minWidth: 200,
      flex: 3,
    });
    columns.push({
      field: `${party.id}-min-votes`,
      headerName: `${party.name} ${local.minvotes}`,
      valueFormatter: numberValueFormatter,
      minWidth: 200,
      flex: 3,
    });
    columns.push({
      field: `${party.id}-swing`,
      headerName: `${party.name} ${local.swing}`,
      valueFormatter: numberValueFormatter,
      minWidth: 200,
      flex: 3,
    });
  });
  return columns;
}
