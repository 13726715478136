import { dateValueFormatter, userValueFormatter } from '../../../../util/formatter';

export default function AgreementsTableColumnDefinition(
  local,
  city,
  district,
) {
  const colDef = [{
    type: 'string',
    field: 'status',
    headerName: local.status,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'description',
    headerName: local.description,
    minWidth: 1000,
    flex: 1,
  }, {
    type: 'date',
    field: 'until',
    headerName: local.until,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'category',
    headerName: local.category,
    minWidth: 250,
    flex: 1,
  }, {
    type: 'string',
    field: 'creator',
    headerName: local.creator,
    valueFormatter: userValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'date',
    field: 'createdAt',
    headerName: local.createdAt,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }, {
    type: 'string',
    field: 'editor',
    headerName: local.editor,
    valueFormatter: userValueFormatter,
    minWidth: 250,
    flex: 2,
  }, {
    type: 'date',
    field: 'updatedAt',
    headerName: local.updatedAt,
    valueFormatter: dateValueFormatter,
    minWidth: 150,
    flex: 1,
  }];
  if (!city) {
    colDef.unshift({
      field: 'city',
      type: 'string',
      headerName: local.city,
      minWidth: 180,
      flex: 1,
    });
  }
  if (!district) {
    colDef.unshift({
      field: 'district',
      type: 'string',
      headerName: local.district,
      minWidth: 180,
      flex: 1,
    });
  }
  return colDef;
}
