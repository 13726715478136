import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar } from '@devexpress/dx-react-scheduler-material-ui';
import {
  Box, FormControlLabel, Checkbox, Select, MenuItem,
  Button,
} from '@mui/material';

import DownloadIcsButton from './DownloadIcsButton';

export default function CalendarToolbar({
  local,
  events,
  mainResourceName,
  resources,
  showAgreements,
  showBgms,
  showVbgms,
  showOpvs,
  showOnlySpoe,
  onMainRessourceNameChange,
  onShowAgreementsChange,
  onShowBgmsChange,
  onShowVbgmsChange,
  onShowOpvsChange,
  onShowOnlySpoeChange,
  onModeChange,
  archive,
  setArchive,
  ...restProps
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Toolbar.FlexibleSpace {...restProps}>
      <Box display="flex" mr={1}>
        <FormControlLabel control={<Checkbox checked={showAgreements} onChange={(event) => onShowAgreementsChange(event.target.checked)} />} label={local.showAgreementsLabel} />
        <FormControlLabel control={<Checkbox checked={showBgms} onChange={(event) => onShowBgmsChange(event.target.checked)} />} label={local.showBgmsLabel} />
        <FormControlLabel control={<Checkbox checked={showVbgms} onChange={(event) => onShowVbgmsChange(event.target.checked)} />} label={local.showVbgmsLabel} />
        <FormControlLabel control={<Checkbox checked={showOpvs} onChange={(event) => onShowOpvsChange(event.target.checked)} />} label={local.showOpvsLabel} />
        <FormControlLabel
          control={<Checkbox checked={showOnlySpoe} onChange={(event) => onShowOnlySpoeChange(event.target.checked)} />}
          label={local.onlySpoeLabel}
          sx={{ whiteSpace: 'nowrap' }}
        />
        <Button variant={archive ? 'contained' : 'outlined'} sx={{ mr: 1 }} onClick={() => setArchive(!archive)}>Archiv</Button>
        <Button variant="outlined" sx={{ mr: 1 }} onClick={() => onModeChange()}>Tabelle</Button>
        <DownloadIcsButton events={events} />
        <Select
          size="small"
          value={mainResourceName}
          onChange={(event) => onMainRessourceNameChange(event.target.value)}
        >
          {resources.map((ressource) => (
            <MenuItem key={ressource.fieldName} value={ressource.fieldName}>{ressource.title}</MenuItem>
          ))}
        </Select>
      </Box>
    </Toolbar.FlexibleSpace>
  );
}

CalendarToolbar.propTypes = {
  local: PropTypes.shape({
    mainResourceName: PropTypes.string.isRequired,
    showAgreementsLabel: PropTypes.string.isRequired,
    showBgmsLabel: PropTypes.string.isRequired,
    showVbgmsLabel: PropTypes.string.isRequired,
    showOpvsLabel: PropTypes.string.isRequired,
    onlySpoeLabel: PropTypes.string.isRequired,
  }).isRequired,
  events: PropTypes.arrayOf(PropTypes.shape()),
  mainResourceName: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showAgreements: PropTypes.bool.isRequired,
  showBgms: PropTypes.bool.isRequired,
  showVbgms: PropTypes.bool.isRequired,
  showOpvs: PropTypes.bool.isRequired,
  showOnlySpoe: PropTypes.bool.isRequired,
  onMainRessourceNameChange: PropTypes.func.isRequired,
  onShowAgreementsChange: PropTypes.func.isRequired,
  onShowBgmsChange: PropTypes.func.isRequired,
  onShowVbgmsChange: PropTypes.func.isRequired,
  onShowOpvsChange: PropTypes.func.isRequired,
  onShowOnlySpoeChange: PropTypes.func.isRequired,
  onModeChange: PropTypes.func.isRequired,
  archive: PropTypes.bool.isRequired,
  setArchive: PropTypes.func.isRequired,
};

CalendarToolbar.defaultProps = {
  events: undefined,
};
