export default {
  de: {
    month: 'Monat',
    mainResourceName: 'Farbe',
    week: 'Woche',
    day: 'Tag',
    state: 'Land',
    showAgreementsLabel: 'Ziele',
    showBgmsLabel: 'Bgm',
    showVbgmsLabel: 'Vzbgm',
    showOpvsLabel: 'OPV',
    onlySpoeLabel: 'nur SPÖ',
    add: 'Ziel hinzufügen',
    district: 'Bezirk',
    city: 'Gemeinde',
    until: 'Bis',
    category: 'Kategorie',
    creator: 'erstellt von',
    createdAt: 'erstellt am',
    editor: 'bearbeitet von',
    updatedAt: 'bearbeitet am',
    description: 'Beschreibung',
    archive: 'Archiv',
    status: 'Status',
    detailsLabel: 'Details',
    allDay: 'Ganztägig',
    allDayLabel: 'Ganztägig',
    today: 'Heute',
    titleLabel: 'Titel',
    commitCommand: 'Speichern',
    moreInformationLabel: 'Weitere Informationen',
    repeatLabel: 'Wiederholen',
    notesLabel: 'Notizen',
    never: 'Nie',
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    monthly: 'Monatlich',
    yearly: 'Jährlich',
    repeatEveryLabel: 'Alle',
    daysLabel: 'Tag(e)',
    endRepeatLabel: 'Wiederholen beenden',
    onLabel: 'Nach',
    afterLabel: 'Nach',
    occurrencesLabel: 'Wiederholungen',
    weeksOnLabel: 'Wochen',
    monthsLabel: 'Monat(e)',
    ofEveryMonthLabel: 'jedes Monats',
    theLabel: 'Der',
    firstLabel: 'Erster',
    secondLabel: 'Zweite',
    thirdLabel: 'Dritte',
    fourthLabel: 'Vierte',
    lastLabel: 'Letzte(s)',
    yearsLabel: 'Jahr(e)',
    ofLabel: 'im',
    everyLabel: 'Jeden',
    delete: 'Löschen',
    cancel: 'Abbrechen',
  },
};
