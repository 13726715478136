import moment from 'moment';

export default function CalendarTableRowsMapper({
  cities, districts, events, archive,
}) {
  let rows;
  if (cities && districts && events) {
    rows = events
      .filter((event) => archive || moment(event.startDate).isAfter(moment().subtract(1, 'days')))
      .map((event) => {
        const district = districts.find((item) => item.id === event.district);
        const city = cities.find((item) => item.id === event.city);
        let type = 'Veranstaltung';
        if (event.type.includes('agreement')) {
          type = 'Ziel';
        }
        if (event.type.includes('official')) {
          type = 'Geburtstag';
        }
        return {
          id: event.object.id,
          district: district ? district.name : '-',
          city: city ? city.name : '-',
          type,
          title: event.title,
          startDate: event.startDate,
          startTime: event.startDate,
          endDate: event.endDate,
          endTime: event.endDate,
          notes: event.notes,
        };
      });
  }
  return rows;
}
