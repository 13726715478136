import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Autocomplete from '@mui/material/Autocomplete';

import { LocalizationContext, SocketContext, UserContext } from '../../../../AppContext';
import Label from '../../../../components/Label';
import Snackbar from '../../../../components/Snackbar';
import {
  BOOLEAN, DATE, DROPDOWN, NUMBER, TEXT,
} from '../../../../config/citysettingtypes';
import hasWriteAccess from '../../../../util/hasWriteAccess';

import localization from './InfoCitySettings.local';

// const renderElement = {
//   BOOLEAN: (label, value, onChange) => (
//     <FormControlLabel
//       sx={{ p: 1, pt: 2 }}
//       label={label}
//       control={(
//         <Checkbox
//           checked={value}
//           onChange={onChange}
//         />
//       )}
//     />
//   ),
//   NUMBER: (label, value, onChange) => (
//     <TextField
//       fullWidth
//       type="number"
//       variant="outlined"
//       color="secondary"
//       margin="dense"
//       label={label}
//       value={value}
//       onChange={onChange}
//     />
//   ),
//   DATE: (label, value, onChange) => (
//     <TextField
//       fullWidth
//       type="date"
//       variant="outlined"
//       color="secondary"
//       margin="dense"
//       label={label}
//       value={value}
//       onChange={onChange}
//     />
//   ),
//   DROPDOWN: (label, value, onChange, options) => (
//     <Autocomplete
//       value={value}
//       options={options}
//       onChange={onChange}
//       getOptionLabel={((option) => option.name)}
//       renderInput={(params) => (
//         <TextField
//           fullWidth
//           // eslint-disable-next-line react/jsx-props-no-spreading
//           {...params}
//           variant="outlined"
//           color="secondary"
//           margin="dense"
//           label={label}
//         />
//       )}
//     />
//   ),
// };

export default function InfoCitySettings({
  cityid,
  citysettingdefinitions,
  citysettings,
  districtid,
  onSetCitysettings,
  users,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const user = useContext(UserContext);
  const [edit, setEdit] = useState();
  const [values, setValues] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (citysettingdefinitions && citysettings && users) {
      const newValues = {};
      citysettingdefinitions
        .filter((item) => item.active)
        .forEach((definition) => {
          const citysetting = citysettings.find((item) => item.city === cityid && item.definition === definition.id);
          newValues[definition.id] = citysetting ? {
            setting: citysetting.id,
            value: citysetting.value,
            type: citysetting.type === 0 ? local.manual : local.report,
            updatedAt: moment(citysetting.updatedAt).format('DD.MM.YYYY'),
            editor: users.find((item) => item.id === citysetting.editor),
          } : { };
        });
      setValues(newValues);
    }
  }, [cityid, citysettingdefinitions, citysettings, users]);

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetCitysettings(citysettings.map((item) => (item.id === payload.id ? payload : item)));
    }
  };

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetCitysettings(citysettings ? [...citysettings, payload] : [payload]);
    }
  };

  const onChange = (definitionid, value) => {
    setValues({ ...values, [definitionid]: { ...values[definitionid], value } });
    const id = values[definitionid].setting;
    const params = {
      city: cityid,
      district: districtid,
      type: 0,
      value,
    };
    if (id) {
      params.id = id;
      socket.emit('citysettings.patch', params, acknowledgePatch);
    } else {
      params.definition = definitionid;
      socket.emit('citysettings.post', params, acknowledgePost);
    }
  };

  const onTextChange = (definitionid, value) => {
    setValues({ ...values, [definitionid]: { ...values[definitionid], value } });
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => onChange(definitionid, value), 1000));
  };

  return (
    <Paper sx={{ width: '100%', p: 1, pb: 2 }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      <Box display="flex">
        <Typography variant="h6">{local.title}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        { hasWriteAccess(user, districtid, cityid) && (
          <Button color="secondary" size="small" variant="text" onClick={() => setEdit(!edit)}>
            {edit ? local.cancel : local.edit}

          </Button>
        )}
      </Box>
      <Grid container spacing={edit ? 1 : 0}>
        {values && citysettingdefinitions && citysettingdefinitions
          .filter((item) => item.active && item.display)
          .sort((a, b) => a.sort - b.sort)
          .map((definition) => {
            let element;
            if (edit) {
              if (definition.type === BOOLEAN) {
                element = (
                  <Box sx={{ p: 1, pt: 2 }}>
                    <Typography>{definition.name}</Typography>
                    <ButtonGroup variant="contained" color="secondary">
                      <Button
                        size="small"
                        color={parseInt(values[definition.id].value, 10) === 1 ? 'primary' : 'secondary'}
                        onClick={() => onChange(definition.id, parseInt(values[definition.id].value, 10) === 1 ? '' : '1')}
                      >
                        {local.true}
                      </Button>
                      <Button
                        size="small"
                        color={parseInt(values[definition.id].value, 10) === 0 ? 'primary' : 'secondary'}
                        onClick={() => onChange(definition.id, parseInt(values[definition.id].value, 10) === 0 ? '' : '0')}
                      >
                        {local.false}
                      </Button>
                    </ButtonGroup>
                  </Box>
                );
              } else if (definition.type === TEXT) {
                element = (
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={definition.name}
                    value={values[definition.id].value || ''}
                    onChange={(e) => onTextChange(definition.id, e.target.value)}
                  />
                );
              } else if (definition.type === NUMBER) {
                element = (
                  <TextField
                    fullWidth
                    type="number"
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={definition.name}
                    value={parseFloat(values[definition.id].value, 10) || ''}
                    onChange={(e) => onTextChange(definition.id, e.target.value)}
                  />
                );
              } else if (definition.type === DATE) {
                element = (
                  <TextField
                    fullWidth
                    type="date"
                    variant="outlined"
                    color="secondary"
                    margin="dense"
                    label={definition.name}
                    value={values[definition.id].value || ''}
                    onChange={(e) => onTextChange(definition.id, e.target.value)}
                  />
                );
              } else if (definition.type === DROPDOWN) {
                const value = definition.options.find((item) => item.id.toString() === values[definition.id].value);
                element = (
                  <Autocomplete
                    value={value || null}
                    options={definition.options}
                    onChange={(e, selected) => onChange(definition.id, selected.id)}
                    getOptionLabel={((option) => option.name)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                      // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="outlined"
                        color="secondary"
                        margin="dense"
                        label={definition.name}
                      />
                    )}
                  />
                );
              }
            } else {
              let mappedValue = '-';
              if (values[definition.id].value) {
                if (definition.type === BOOLEAN) {
                  if (parseInt(values[definition.id].value, 10) === 1) {
                    mappedValue = local.true;
                  }
                  if (parseInt(values[definition.id].value, 10) === 0) {
                    mappedValue = local.false;
                  }
                } else if (definition.type === DATE) {
                  mappedValue = moment(values[definition.id].value).format('DD.MM.YYYY');
                } else if (definition.type === DROPDOWN) {
                  const option = definition.options.find((item) => item.id.toString() === values[definition.id].value);
                  mappedValue = option ? option.name : mappedValue;
                } else {
                  mappedValue = values[definition.id].value;
                }
              }
              element = <Label label={definition.name} value={mappedValue} />;
            }
            return (
              <Grid key={definition.id} item xs={6}>
                {element}
                { edit && values[definition.id].updatedAt && values[definition.id].editor && (
                  <Box>
                    <Typography variant="caption" sx={{ ml: 1 }}>
                      {`${values[definition.id].updatedAt}, ${values[definition.id].editor.firstname} ${values[definition.id].editor.lastname}, ${values[definition.id].type}`}
                    </Typography>
                  </Box>
                )}
              </Grid>
            );
          })}
      </Grid>
    </Paper>
  );
}

InfoCitySettings.propTypes = {
  cityid: PropTypes.number.isRequired,
  citysettingdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  citysettings: PropTypes.arrayOf(PropTypes.shape({})),
  districtid: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onSetCitysettings: PropTypes.func.isRequired,
};

InfoCitySettings.defaultProps = {
  citysettingdefinitions: undefined,
  citysettings: undefined,
  users: undefined,
};
