import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { LocalizationContext, SocketContext } from '../../../../AppContext';
import Snackbar from '../../../../components/Snackbar';
import Loading from '../../../../components/Loading';
import ReportsToolbar from './ReportsToolbar';
import ColumnDefinition from './ReportsTableColumnDefinition';
import RowsMapper from './ReportsTableRowsMapper';
import localization from './ReportsTable.local';

export default function ReportsTable({
  cities,
  city,
  district,
  districts,
  reportdefinitions,
  reports,
  reportstatuses,
  users,
  gridviews,
  onRowClick,
  onAddClick,
  onSetGridviews,
}) {
  const local = localization[useContext(LocalizationContext)];
  const socket = useContext(SocketContext);
  const [rows, setRows] = useState();
  const [columns, setColumns] = useState();
  const [archive, setArchive] = useState(false);
  const [gridview, setGridview] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => setColumns(ColumnDefinition(local, city, district)), [city, district]);
  useEffect(() => setRows(RowsMapper(
    local,
    archive,
    cities,
    city,
    district,
    districts,
    reportdefinitions,
    reports,
    reportstatuses,
    users,
  )), [
    archive,
    cities,
    city,
    district,
    districts,
    reportdefinitions,
    reports,
    reportstatuses,
    users,
  ]);

  const onChangeGridview = (selectedGridview) => setGridview(selectedGridview || {});

  const acknowledgePost = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? [...gridviews, payload] : [payload]);
      setGridview(payload);
    }
  };

  const onPostGridview = (grid, view, global) => {
    socket.emit('gridviews.post', {
      ...gridview, view, grid, global,
    }, acknowledgePost);
  };

  const acknowledgePatch = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews ? gridviews.map((item) => (item.id === payload.id ? payload : item)) : [payload]);
      setGridview(payload);
    }
  };
  const onPatchGridview = (view, global) => {
    socket.emit('gridviews.patch', { ...gridview, view, global }, acknowledgePatch);
  };

  const acknowledgeDelete = ({ error, payload }) => {
    setAlert(error || 200);
    if (!error) {
      onSetGridviews(gridviews.filter((item) => item.id !== payload.id));
      setGridview({});
    }
  };
  const onDeleteGridview = () => {
    socket.emit('gridviews.destroy', gridview, acknowledgeDelete);
  };

  return (
    <Paper sx={{ height: '100%' }}>
      <Snackbar alert={alert} local={local.alerts} onClose={() => setAlert()} />
      { columns && rows ? (
        <DataGridPro
          sx={{ height: '100%' }}
          rows={rows}
          columns={columns}
          onRowClick={({ row }) => onRowClick(row.id)}
          components={{ Toolbar: ReportsToolbar }}
          componentsProps={{
            toolbar: {
              local,
              archive,
              city,
              onAddClick,
              onArchiveClick: setArchive,
              gridview,
              gridviews,
              onChangeGridview,
              onPostGridview,
              onPatchGridview,
              onDeleteGridview,
            },
          }}
          columnVisibilityModel={gridview?.columnVisibilityModel || {}}
          sortModel={gridview?.sortModel || []}
          filterModel={gridview?.filterModel || { items: [] }}
          onColumnVisibilityModelChange={(model) => setGridview({ ...gridview, columnVisibilityModel: model })}
          onSortModelChange={(model) => setGridview({ ...gridview, sortModel: model })}
          onFilterModelChange={(model) => setGridview({ ...gridview, filterModel: model })}
        />
      ) : (
        <Loading sx={{ pt: 10 }} />
      )}
    </Paper>
  );
}

ReportsTable.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  city: PropTypes.shape({
    id: PropTypes.number,
  }),
  district: PropTypes.shape({
    id: PropTypes.number,
  }),
  districts: PropTypes.arrayOf(PropTypes.shape({})),
  reportdefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  reports: PropTypes.arrayOf(PropTypes.shape({})),
  reportstatuses: PropTypes.arrayOf(PropTypes.shape({})),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  gridviews: PropTypes.arrayOf(PropTypes.shape({})),
  onSetGridviews: PropTypes.func.isRequired,
};

ReportsTable.defaultProps = {
  cities: undefined,
  city: undefined,
  district: undefined,
  districts: undefined,
  reportdefinitions: undefined,
  reports: undefined,
  reportstatuses: undefined,
  users: undefined,
  gridviews: undefined,
};
