export default {
  de: {
    add: 'Ziel hinzufügen',
    district: 'Bezirk',
    city: 'Gemeinde',
    until: 'Bis',
    category: 'Kategorie',
    creator: 'erstellt von',
    createdAt: 'erstellt am',
    editor: 'bearbeitet von',
    updatedAt: 'bearbeitet am',
    description: 'Beschreibung',
    archive: 'Archiv',
    status: 'Status',
    alerts: {
      200: () => 'Änderungen wurden gespeichert!',
      400: () => 'Achtung! Deine Änderung konnten nicht gespeichert werden!',
      500: () => 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
    },
  },
};
