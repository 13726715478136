import moment from 'moment';

export default ({
  agreement, agreementcategories, city, district, archive,
}) => {
  const isDistrict = !district || district.id === agreement.district;
  const isCity = !city || city.id === agreement.city;
  const isActive = agreement.active;
  const isArchive = archive || moment(agreement.until).isAfter(moment().subtract(1, 'days'));
  const agreementCategory = agreementcategories.find((item) => item.id === agreement.category);
  return isActive && isDistrict && isCity && isArchive && agreementCategory.active;
};
